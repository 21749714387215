import { Storage, StorageProps } from '@api/models';

import { StorageEnrichedModel } from '../models';
import { SortFn } from './array.utils';
import { toNum, toStr } from './string.utils';

interface StorageWithNum<T extends number | string>
  extends Omit<Storage, 'number'> {
  number: T;
}

type StorageNumberNum = StorageWithNum<number>;
type StorageNumberStr = StorageWithNum<string>;

// Awesome typing inference! :D
type ToStorageSortFn = <P extends StorageProps>(
  prop: P,
  fn: SortFn<Storage[P]>
) => (a: Storage, b: Storage) => number;

export const toStorageSort: ToStorageSortFn = (prop, fn) => (a, b) =>
  fn(a[prop], b[prop]);

const splitStoragesByNumberType = (list: Storage[]) =>
  list.reduce(
    (acc, curr) => {
      const asNum = toNum(curr.number);
      if (isNaN(asNum)) {
        acc[1] = [...acc[1], { ...curr, number: toStr(curr.number) }];
      } else {
        acc[0] = [...acc[0], { ...curr, number: asNum }];
      }
      return acc;
    },
    [[], []] as [StorageNumberNum[], StorageNumberStr[]]
  );

export const listStrFirst = (storages: Storage[]): Storage[] => {
  const [numArr, strArr] = splitStoragesByNumberType(storages);
  return [...strArr, ...numArr] as Storage[];
};

export const getIsToggleUpdate = (
  storage: StorageEnrichedModel,
  formData: Storage
): boolean =>
  !!storage.startDate && storage.storedOnCustomer !== formData.storedOnCustomer;

export const getIsNotesUpdate = (
  storage: StorageEnrichedModel,
  formData: Storage
): boolean => !!storage.startDate && storage.notes !== formData.notes;
