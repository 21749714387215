import { COLORS } from '../../styles';
import { IconProps } from '../../models';

export function UserIcon(props: IconProps) {
  const color = COLORS[props.color || 'blue'];
  return (
    <svg
      className={props.className}
      width={props.width || 20}
      height={props.height || 18}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8146 9.56815C16.1227 7.36425 16.1043 3.81957 13.7735 1.63696C11.4427 -0.545654 7.67572 -0.545654 5.34491 1.63696C3.0141 3.81957 2.99573 7.36425 5.30381 9.56815C2.11032 10.7496 0.00764037 13.6466 0 16.8756C0 17.4966 0.534976 18 1.1949 18H17.9235C18.5835 18 19.1184 17.4966 19.1184 16.8756C19.1108 13.6466 17.0081 10.7496 13.8146 9.56815ZM9.55885 2.25839C11.5386 2.25839 13.1436 3.76862 13.1436 5.63158C13.1436 7.49454 11.5386 9.00477 9.55885 9.00477C7.57907 9.00477 5.97415 7.49454 5.97415 5.63158C5.97632 3.76947 7.57998 2.26043 9.55885 2.25839ZM8.36456 11.2535C5.52671 11.2573 3.08164 13.1355 2.50954 15.7511H16.6094C16.0373 13.1355 13.5922 11.2573 10.7544 11.2535H8.36456Z"
      />
    </svg>
  );
}
