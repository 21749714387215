import { useState } from 'react';

import { UserModelKeys, UserOptionalPropsModel } from '@api/models';
import { UserRoles } from '../../enums';
import { TypeWithValues, UserUiModel } from '../../models';
import { hasErrors } from '../../utils';
import { EditingList, Form, Input, RadioGroup, RadioItem } from '../shared';

type AddUserErrors = TypeWithValues<UserOptionalPropsModel, boolean>;

const userRoles: RadioItem[] = [
  {
    label: 'Foreman',
    value: UserRoles.Foreman,
    name: UserRoles.Foreman
  },
  {
    label: 'Admin',
    value: UserRoles.Admin,
    name: UserRoles.Admin
  }
];

interface ComponentProps {
  users?: UserUiModel[];
  onSubmit: (formData: UserOptionalPropsModel) => void;
  onDelete: (user: UserUiModel) => void;
}

export function AddUserForm(props: ComponentProps) {
  const { users = [] } = props;
  const formEmptyState = {
    role: UserRoles.Foreman
  };
  const [formState, setFormState] =
    useState<UserOptionalPropsModel>(formEmptyState);
  const [formErrors, setFormErrors] = useState<AddUserErrors>({});

  const handleChange = ({ target }: any) => {
    const { name, value } = target as { name: UserModelKeys; value: string };
    setFormState({
      ...formState,
      [name]: value
    });
    if (value && formErrors[name as UserModelKeys]) {
      setFormErrors({
        ...formErrors,
        [name]: false
      });
    }
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const errors: AddUserErrors = {
      name: !formState.name,
      email: !formState.email,
      password: !formState.password
    };
    setFormErrors(errors);

    if (!hasErrors(errors)) {
      props.onSubmit(formState);
    }
  };

  const onEdit = ({ _id, name, email, role }: UserUiModel) => {
    setFormState({ _id, name, email, role, password: '' });
  };

  const onDelete = (user: UserUiModel) => {
    props.onDelete(user);
  };

  const onCancel = () => {
    setFormState(formEmptyState);
    setFormErrors({});
  };

  return (
    <>
      <Form
        submitLabel={formState._id ? 'update' : 'register'}
        onSubmit={onSubmit}
        hasError={hasErrors(formErrors)}
        errorMessage="Please, fill all the fields"
      >
        <div className="form-col is-full">
          <label className="form-label">Name</label>
          <Input
            name="name"
            placeholder="Add name"
            value={formState.name || ''}
            hasError={formErrors.name}
            onChange={handleChange}
            autoComplete="off"
          />
        </div>
        <div className="form-col is-full">
          <label className="form-label">Email</label>
          <Input
            name="email"
            placeholder="Add email"
            value={formState.email || ''}
            hasError={formErrors.email}
            onChange={handleChange}
            autoComplete="off"
          />
        </div>
        <div className="form-col is-full">
          <label className="form-label">Password</label>
          <Input
            name="password"
            placeholder="Add password"
            value={formState.password || ''}
            hasError={formErrors.password}
            onChange={handleChange}
            autoComplete="off"
          />
        </div>
        <div className="form-col is-full">
          <label className="form-label">Choose the type of user</label>
          <RadioGroup
            name="role"
            items={userRoles}
            value={formState.role as string}
            onChange={handleChange}
          ></RadioGroup>
        </div>
      </Form>
      <EditingList<UserUiModel>
        list={users}
        onCancel={onCancel}
        onEdit={onEdit}
        onDelete={onDelete}
      >
        {{
          title: 'Registered Users',
          itemLabel: ({ name, role }) => `${name} - ${role}`
        }}
      </EditingList>
    </>
  );
}
