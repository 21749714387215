import axios from 'axios';

import { UserOptionalPropsModel, UserModel } from '@api/models';

export class UsersHttpService {
  static fetch() {
    return axios.get<UserModel[]>(this.path());
  }

  static add(user: UserOptionalPropsModel) {
    return axios.post<UserModel>(this.path(), user);
  }

  static update(user: UserOptionalPropsModel) {
    return axios.put<UserModel>(this.path(user._id), user);
  }

  static delete(locationId: string) {
    return axios.delete<any>(this.path(locationId));
  }

  static path(path: string = ''): string {
    const endPath = path ? `/${path}` : '';
    return `/api/users${endPath}`;
  }
}
