export type SortFn<T> = (a: T, b: T) => number;

export const sortNumAsc: SortFn<number> = (a, b) => a - b;

export const sortNumDesc: SortFn<number> = (a, b) => b - a;

export const sortStrDesc: SortFn<string> = (strA, strB) => {
  const aUp = strA.toUpperCase();
  const bUp = strB.toLocaleUpperCase();
  return aUp < bUp ? -1 : aUp > bUp ? 1 : 0;
};

export const sortStrAsc: SortFn<string> = (strA, strB) =>
  sortStrDesc(strB, strA);

const localeOpts = { numeric: true, sensitivity: 'base' };
export const sortNumStrAsc: SortFn<string> = (strA, strB) =>
  strA.localeCompare(strB, undefined, localeOpts);

export const sortNumStrDesc: SortFn<string> = (strA, strB) =>
  strB.localeCompare(strA, undefined, localeOpts);

export const unique = (
  value: string | number,
  index: number,
  arr: Array<string | number>
): boolean => arr.indexOf(value) === index;
