import styled from 'styled-components';

import { COLORS } from '../../styles';
import { ArrowIcon } from '../icons';

const SelectStyles = styled.div`
  width: 100%;
  position: relative;

  .select__element {
    appearance: none;
    background-color: white;
    border: 1px solid ${COLORS.greyLight};
    border-radius: 10px;
    box-shadow: 0 0.25em ${COLORS.greyLight};
    padding: 1rem 1.8rem 1rem 1rem;
    width: 100%;

    &:focus {
      outline: 0;
    }

    &.has-error {
      border-color: ${COLORS.red};
    }
  }

  .select__icon {
    display: block;
    transform: rotate(90deg);
    position: absolute;
    top: calc(50% - 5px);
    right: 1rem;
  }
`;

export interface SelectItem {
  value: string;
  label: string;
}

export interface SelectProps {
  name: string;
  value: string;
  items: SelectItem[];
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  hasError?: boolean;
}

export function Select(props: SelectProps) {
  return (
    <SelectStyles>
      <select
        className={`select__element ${props.hasError ? 'has-error' : ''}`}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      >
        {props.items.map((item) => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      <ArrowIcon className="select__icon" />
    </SelectStyles>
  );
}
