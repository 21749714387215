import axios from 'axios';

export class AuthenticationHttpService {
  static authenticate(email: string, password: string) {
    return axios.post<any>(this.path('users/authenticate'), {
      email,
      password
    });
  }

  static path(path: string): string {
    return `/api/${path}`;
  }
}
