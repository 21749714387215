import { COLORS } from '../../styles';
import { IconProps } from '../../models';

export function SearchIcon(props: IconProps) {
  const color = COLORS[props.color || 'blue'];
  return (
    <svg
      className={props.className}
      width={props.width || 24}
      height={props.height || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.19991 16.3998C13.1763 16.3998 16.3998 13.1763 16.3998 9.19991C16.3998 5.22351 13.1763 2 9.19991 2C5.22351 2 2 5.22351 2 9.19991C2 13.1763 5.22351 16.3998 9.19991 16.3998Z"
        stroke={color}
        fill="#fff"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9999 19.9999L14.24 14.24"
        stroke={color}
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
