import { memo, useContext, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import { Storage } from '@api/models';
import { SortStorageEnum } from '../../enums';
import { COLORS, MEDIAS } from '../../styles';
import {
  LocationsModel,
  ScrollToStorageModel,
  SearchModel
} from '../../models';
import { storageActions, StoragesDispatchContext } from '../../state/storages';
import { getLocationsList } from '../../state/storages/selectors';
import { StorageItem } from '../elements/StorageItem';
import { Spinner } from '../shared';

const StorageListStyled = styled.div`
  padding-bottom: 2.5rem;

  .location__title {
    background: white;
    box-shadow: 0px 10px 15px -10px ${COLORS.shadow};
    color: ${COLORS.grey};
    margin: 0;
    padding: 0 1.25rem 0.75rem;
    position: sticky;
    top: -1px;
    font-size: 0.85rem;
  }

  .location__list {
    padding: 0.5rem 0.75rem 1.5rem;
  }

  .location__item {
    padding: 0.25rem 0;
  }

  .location__empty-msg {
    color: ${COLORS.greyMedium};
    font-size: 0.85rem;
    font-style: italic;
    margin: 0;
    padding: 0 1rem 1rem;
  }

  .spinner {
    display: block;
    margin: 3rem auto;
    text-align: center;
  }

  @media ${MEDIAS.large} {
    .location__list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 0.75rem 0.75rem;
      padding: 0 1rem 2rem;
    }

    .location__title {
      box-shadow: 0 0 0;
      padding-top: 0;
      padding-bottom: 1rem;
    }

    .location__item {
      padding: 0;
    }
  }
`;

interface StorageRefsModel {
  [locationId: string]: {
    [storageId: string]: any;
  };
}

interface StoragesListProps {
  locations: LocationsModel;
  search: SearchModel;
  sort: SortStorageEnum | null;
  scrollToStorage: ScrollToStorageModel;
  onStorageClick: (storage: Storage) => void;
}

export const StoragesList = memo((props: StoragesListProps) => {
  const storagesRef = useRef<StorageRefsModel>({} as any);
  const dispatch = useContext(StoragesDispatchContext);

  const { locations, search, sort, scrollToStorage } = props;
  const locationsList = useMemo(
    () => getLocationsList(locations, search, sort),
    [locations, search, sort]
  );
  const searchTerm = search?.term;

  useEffect(() => {
    const { locationId, storageId, elementPosition } = scrollToStorage;
    if (!!locationId && !!storageId && !elementPosition) {
      const element =
        storagesRef.current[locationId as string][storageId as string];
      dispatch(
        storageActions.setScrollTo({ elementPosition: element?.offsetTop })
      );
    }
  }, [scrollToStorage, dispatch]);

  const storeElRef =
    (locationId: string, storageId: string) =>
    (el: HTMLDivElement): void => {
      if (!storagesRef.current[locationId]) {
        storagesRef.current[locationId] = {};
      }
      storagesRef.current[locationId][storageId] = el;
    };

  const isHighlighted = (locationId: string, storageId: string): boolean =>
    scrollToStorage?.locationId === locationId &&
    scrollToStorage?.storageId === storageId;

  return (
    <StorageListStyled>
      {locationsList.length ? (
        locationsList.map((location) => (
          <div key={`${location._id}-${location.storages.length}`}>
            <h2 className="location__title">{location.address}</h2>
            <div className="location__list">
              {location.storages?.length ? (
                location.storages.map((storage) => (
                  <div
                    className="location__item"
                    key={`${location._id}-${storage._id}`}
                    ref={storeElRef(location._id, storage._id)}
                  >
                    <StorageItem
                      storage={storage}
                      highlight={isHighlighted(location._id, storage._id)}
                      onClick={() => props.onStorageClick(storage)}
                    ></StorageItem>
                  </div>
                ))
              ) : (
                <p className="location__empty-msg">
                  {!searchTerm
                    ? 'No storages added to this location yet'
                    : 'No storages found in this location'}
                </p>
              )}
            </div>
          </div>
        ))
      ) : (
        <Spinner color="blue" size="big" className="spinner" />
      )}
    </StorageListStyled>
  );
});
