import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button, ErrorMessage, Input, Spinner } from '../shared';
import { MAIN_PATH } from '../../constants';
import { COLORS, MEDIAS } from '../../styles';
import { AuthenticationHttpService } from '../../services';
import { storeSessionData } from '../../utils';

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${COLORS.blue};
    color: #fff;
    width: 100%;
    height: 260px;
    text-align: center;
    padding-top: 2rem;
    justify-content: space-between;
  }

  .logo {
    width: 150px;
    margin-bottom: -60px;

    &__img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 100px 2rem 3rem;
  }

  .error-message {
    margin-bottom: 1rem;
  }

  .input {
    margin-bottom: 1.25rem;
  }

  @media ${MEDIAS.large} {
    flex-direction: row;

    .header {
      width: 50%;
      min-height: 100vh;
      padding: 3rem;
      justify-content: center;
    }

    .logo {
      margin: 2rem 0 0;
    }

    .content {
      align-items: center;
      justify-content: center;
    }

    .input,
    .button {
      max-width: 350px;
      width: 100%;
    }
  }
`;

export function Login() {
  const history = useHistory();
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const emailEl = useRef<HTMLInputElement>(null);
  const passwordEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    document.body.classList.remove('no-scroll');
  }, []);

  async function onSubmit() {
    const email = emailEl.current?.value;
    const password = passwordEl.current?.value;

    if (!email || !password) {
      setErrorMessage('Please, fill user and password');
      return;
    }

    setErrorMessage(null);
    setInProgress(true);

    try {
      const result = await AuthenticationHttpService.authenticate(
        email,
        password
      );
      if (result.status === 200) {
        storeSessionData(result.data);
        history.push(`/${MAIN_PATH}`);
      } else {
        throw result;
      }
    } catch (err: any) {
      const { message, error } = err.response.data;
      setErrorMessage(error || message);
      setInProgress(false);
    }
  }

  return (
    <LoginWrapper>
      <div className="header">
        <h1>Storage System</h1>
        <div className="logo">
          <img className="logo__img" src={'/svg/logo.svg'} alt="Lunardi Logo" />
        </div>
      </div>
      <div className="content">
        {errorMessage && (
          <div className="error-message">
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </div>
        )}
        <Input className="input" placeholder="your email" ref={emailEl}></Input>
        <Input
          className="input"
          placeholder="password"
          type="password"
          ref={passwordEl}
        ></Input>
        <Button className="button" onClick={onSubmit}>
          {inProgress ? <Spinner /> : 'submit'}
        </Button>
      </div>
    </LoginWrapper>
  );
}
