import styled from 'styled-components';

import { COLORS } from '../../styles';

interface H2Props {
  color?: keyof typeof COLORS;
}

export const H2 = styled.h2`
  color: ${(props: H2Props) => COLORS[props.color || 'blue']};
  font-size: 1.75rem;
  margin: 0;
`;
