import styled from 'styled-components';

import { Storage } from '@api/models';
import { COLORS, REGION_COLORS } from '../../styles';
import { toUsDateStr } from '../../utils';
import { StatusLabel } from '../shared';

const StorageItemStyles = styled.div`
  display: flex;
  background: white;
  border: 1px solid ${COLORS.greyLight};
  border-left-width: 0.5rem;
  border-radius: 10px;
  color: ${COLORS.black};
  padding: 0.65rem 1.15rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &.is-highlighted {
    border-color: ${COLORS.greyMedium};
    box-shadow: 0px 10px 15px -10px ${COLORS.greyMedium};
  }

  &.is-highlighted {
    transform: scale() (1.05);
  }

  p {
    margin: 0;
  }

  .col {
    align-self: center;
    display: flex;

    &--num {
      align-items: center;
      flex-direction: column;
      padding-right: 1rem;
    }

    &--info {
      flex-direction: column;
      flex-grow: 1;
    }

    &__line {
      display: flex;
      flex-direction: row;
      width: 100%;
      column-gap: 0.5rem;

      &:first-child {
        padding-bottom: 0.5rem;
      }
    }
  }

  .number {
    font-size: 2.75rem;
    line-height: 0.7;
    padding-bottom: 0.5rem;
    letter-spacing: -3px;
  }

  .size {
    font-size: 0.75rem;
  }

  .project-number {
    flex-grow: 1;
    font-size: 0.85rem;
    color: ${COLORS.grey};
  }

  .client-name {
    flex-grow: 1;
    font-style: italic;
    font-weight: bold;
  }
`;

export interface StorageItemProps {
  storage: Storage;
  onClick: (storageId: Storage) => void;
  highlight?: boolean;
}

export function StorageItem(props: StorageItemProps) {
  const storedOnCustomerColor = props.storage?.storedOnCustomer && COLORS.black;
  const borderColor =
    storedOnCustomerColor ||
    REGION_COLORS[props.storage?.position] ||
    COLORS.greyMedium;

  return (
    <StorageItemStyles
      className={`${props.highlight ? 'is-highlighted' : ''}`}
      style={{ borderLeftColor: borderColor }}
      onClick={() => props.onClick(props?.storage)}
    >
      <div className="col col--num">
        <span className="number">{props.storage.number}</span>
        <span className="size">
          {props.storage.depth} x {props.storage.width}
        </span>
      </div>
      <div className="col col--info">
        <div className="col__line">
          <p className="project-number">
            {props.storage.projectNumber
              ? `Project ${props.storage.projectNumber}`
              : '--'}
          </p>
          <p>
            <StatusLabel isUsed={!!props.storage.startDate}></StatusLabel>
          </p>
        </div>
        <div className="col__line">
          <p className="client-name">{props.storage.clientName || '--'}</p>
          <p>
            {props.storage.startDate
              ? toUsDateStr(props.storage.startDate)
              : '--'}
          </p>
        </div>
      </div>
    </StorageItemStyles>
  );
}
