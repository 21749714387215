import axios from 'axios';

import { HistoryModel, Storage } from '@api/models';
import { NewStorageData } from '../models';

export type StorageStartUsagePayload = Pick<
  Storage,
  '_id' | 'clientName' | 'projectNumber' | 'startDate' | 'storedOnCustomer'
>;

export type StorageUpdateSpecialPropsPayload = Pick<
  Storage,
  '_id' | 'storedOnCustomer' | 'notes'
>;

export type StorageSetEndDatePayload = Pick<Storage, '_id' | 'endDate'>;

export type StorageSetEndDateResponse = {
  storage: Storage;
  history: HistoryModel;
};

export class StoragesHttpService {
  static getStorages() {
    return axios.get<Storage[]>(this.path());
  }

  static add(storageData: NewStorageData) {
    return axios.post<Storage>(this.path(), storageData);
  }

  static delete(storageId: string) {
    return axios.delete<any>(this.path(storageId));
  }

  static update({ _id, ...storageData }: Storage) {
    return axios.put<Storage>(this.path(_id), storageData);
  }

  static startUsage({
    _id,
    clientName,
    projectNumber,
    startDate,
    storedOnCustomer
  }: StorageStartUsagePayload) {
    return axios.put<Storage>(this.path(_id), {
      clientName,
      projectNumber,
      startDate,
      storedOnCustomer
    });
  }

  static updateSpecialFields({
    _id,
    storedOnCustomer,
    notes
  }: StorageUpdateSpecialPropsPayload) {
    return axios.put<Storage>(this.path(_id), {
      storedOnCustomer,
      notes
    });
  }

  static setEndDate({ _id, endDate }: StorageSetEndDatePayload) {
    return axios.post<StorageSetEndDateResponse>(
      this.path(`setEndDate/${_id}`),
      {
        endDate
      }
    );
  }

  static path(path: string = ''): string {
    const prefixedPath = path ? `/${path}` : '';
    return `/api/storages${prefixedPath}`;
  }
}
