import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { UserOptionalPropsModel } from '@api/models';
import { MAIN_PATH } from '../../constants';
import { UserUiModel } from '../../models';
import { UsersHttpService } from '../../services';
import {
  getUsers,
  storageActions,
  StoragesDispatchContext,
  StoragesStateContext,
  updateUser
} from '../../state/storages';
import { Takeover } from '../elements';
import { AddUserForm } from '../forms';
import { Message } from '../shared';

const TakeoverStyles = styled.div`
  strong {
    display: inline-block;
    font-size: 1.15rem;
    padding: 0.5rem 0;
  }
`;

export function AddUserTakeover() {
  const takeover = useRef(null);
  const dispatch = useContext(StoragesDispatchContext);
  const [confirmationMsg, setConfirmationMsg] = useState<string>('');
  const [userToDelete, setUserToDelete] = useState<UserUiModel | null>(null);
  const exitTo = `/${MAIN_PATH}`;
  const users = getUsers(useContext(StoragesStateContext));

  let saving = false;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const result = await UsersHttpService.fetch();
        if (result.status === 200) {
          dispatch(storageActions.setUsers(result.data));
        }
      } catch (error: any) {
        alert(error.response.data.error);
      }
    };
    fetchUsers();
  }, [dispatch]);

  const closeTakeover = () => (takeover?.current as any).close();

  const onSubmit = async (formData: UserOptionalPropsModel) => {
    if (saving) {
      return;
    }
    saving = true;

    if (formData._id) {
      // Editing
      const result = await UsersHttpService.update(formData);
      if (result.status === 200) {
        setConfirmationMsg('User Successfully Edited');
        updateUser(result.data);
      }
    } else {
      // Adding
      const result = await UsersHttpService.add(formData);
      if (result.status === 201) {
        setConfirmationMsg('New User Successfully Created');
        dispatch(storageActions.addUser(result.data));
      }
    }
  };

  const onDeleteConfirm = async () => {
    if (saving || !userToDelete) {
      return;
    }
    saving = true;

    try {
      const result = await UsersHttpService.delete(userToDelete._id as string);
      if (result.status === 200) {
        setConfirmationMsg('User Successfully Deleted');
        dispatch(storageActions.deleteUser(result.data));
      }
    } catch (error: any) {
      alert(error.response.data.error);
    } finally {
      saving = false;
    }
  };

  return (
    <TakeoverStyles>
      <Takeover
        fullScreen
        title="Create a New User"
        subtitle="Creating new foreman user or admin"
        ref={takeover}
        fixedContent={!!userToDelete}
        exitTo={exitTo}
      >
        {confirmationMsg ? (
          <Message
            title={confirmationMsg}
            onConfirm={closeTakeover}
            actionLabel="go to dashboard"
            successImg
          />
        ) : (
          <>
            <AddUserForm
              users={users}
              onSubmit={onSubmit}
              onDelete={setUserToDelete}
            />
            {userToDelete && (
              <Message
                title="Are you sure?"
                onConfirm={onDeleteConfirm}
                onCancel={() => setUserToDelete(null)}
                actionLabel="delete"
                deleteImg
                absolute
              >
                The user
                <strong>
                  {userToDelete?.name} ({userToDelete?.email})
                </strong>
                will be permanently deleted.
              </Message>
            )}
          </>
        )}
      </Takeover>
    </TakeoverStyles>
  );
}
