import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

import { COLORS } from '../../styles';

const ToggleStyles = styled.div`
  .toggle-container {
    display: flex;

    &.has-label {
      border: 1px solid ${COLORS.greyLight};
      border-radius: 10px;
      padding: 1rem;
    }

    .toggle-label {
      flex-grow: 1;
      align-self: center;
      padding-right: 1rem;
    }

    & > input {
      display: none;

      & + label {
        cursor: pointer;
        background: ${COLORS.greyLightDarker};
        border-radius: 20px;
        flex-shrink: 0;
        width: 70px;
        height: 35px;
        transition: background 0.2s ease;

        &::before {
          content: ' ';
          display: inline-block;
          width: calc(50% - 2px);
          height: calc(100% - 2px);
          border: 1px solid transparent;
          border-radius: 50%;
          background: white;
          box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15),
            0px 1px 1px rgba(0, 0, 0, 0.08);
          position: relative;
          top: 1px;
          left: 1px;
          transition: all 0.2s ease-in-out;
        }
      }

      &:checked + label {
        background: ${COLORS.orange};

        &::before {
          border-color: ${COLORS.orange};
          transform: translateX(calc(100% + 2px));
        }
      }
    }
  }
`;

export interface ToggleProps {
  name: string;
  toggled: boolean | undefined;
  label?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export function Toggle(props: ToggleProps) {
  const [isToggled, setIsToggled] = useState(props.toggled || false);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setIsToggled(e.target.checked);
    props.onChange(e);
  };

  return (
    <ToggleStyles>
      <div className={`toggle-container ${props.label ? 'has-label' : ''}`}>
        {props.label && <span className="toggle-label">{props.label}</span>}
        <input
          type="checkbox"
          id={props.name}
          name={props.name}
          checked={isToggled}
          onChange={onChange}
        />
        <label className="no-tap-highlight" htmlFor={props.name}></label>
      </div>
    </ToggleStyles>
  );
}
