import { useRef, useState, useCallback } from 'react';

import { PopulatedLocation, LocationModel } from '@api/models';
import { LocationsFormFields } from '../../constants';
import { Button, EditingList, Form, Input } from '../shared';

export type FormAddLocation = Pick<LocationModel, '_id' | 'address'>;

interface ComponentProps {
  locations: PopulatedLocation[];
  onSubmit: (formData: FormAddLocation) => void;
  onDelete: (location: PopulatedLocation) => void;
}

export function AddLocationForm(props: ComponentProps) {
  const { locations } = props;
  const input = useRef<HTMLInputElement>(null);
  const [id, setId] = useState<string | undefined>();
  const [hasError, setHasError] = useState<boolean>();
  const [errorMsg, setErrorMsg] = useState<string>('');

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const address = input.current?.value?.trim();
    if (!!address) {
      setErrorMsg('');
      props.onSubmit({ _id: id as string, address });
    } else {
      setHasError(true);
      setErrorMsg('Please, fill the address field');
    }
  };

  const onEdit = ({ _id, address }: PopulatedLocation) => {
    setId(_id);
    setInputValue(address);
    setHasError(false);
    setErrorMsg('');
  };

  const onDelete = (location: PopulatedLocation) => {
    props.onDelete(location);
  };

  const onCancel = () => {
    setId(undefined);
    setInputValue('');
  };

  const onErrorHide = useCallback(() => {
    setErrorMsg('');
  }, []);

  const setInputValue = (value: string) => {
    (input.current as HTMLInputElement).value = value;
  };

  return (
    <>
      <Form
        submitLabel={id ? 'update' : 'register'}
        onSubmit={onSubmit}
        hasError={!!errorMsg}
        errorMessage={errorMsg}
        onErrorHide={onErrorHide}
      >
        <div className="form-col is-full">
          <label className="form-label">{id ? 'Edit' : 'Add'} Address</label>
          <Input
            name={LocationsFormFields.address}
            placeholder="Insert Address"
            ref={input}
            hasError={hasError}
            autoComplete="off"
          />
        </div>
      </Form>
      <EditingList<PopulatedLocation>
        list={locations}
        onCancel={onCancel}
        onEdit={onEdit}
        onDelete={onDelete}
      >
        {{
          title: 'Registered Locations',
          emptyMessage: 'There are no locations added yet',
          itemLabel: ({ address }) => address
        }}
      </EditingList>
    </>
  );
}
