import { UserUiModel } from '../models';
import { LOGGED_USER_KEY } from '../constants';

interface LoggedData {
  token: string;
  user: UserUiModel;
}

let sessionCache: LoggedData | null;

export const storeSessionData = (sessionData: string): void =>
  window.sessionStorage.setItem(LOGGED_USER_KEY, JSON.stringify(sessionData));

export const unlogUser = (): void => {
  sessionCache = null;
  window.sessionStorage.removeItem(LOGGED_USER_KEY);
};

export const getUserSession = (): string | null =>
  window.sessionStorage.getItem(LOGGED_USER_KEY);

export const getUserSessionData = (): LoggedData | null => {
  if (sessionCache) {
    return sessionCache;
  }
  const userSession = getUserSession();
  sessionCache = userSession ? JSON.parse(userSession) : null;
  return sessionCache;
};

export const getUserToken = (): string | null => {
  const userData = getUserSessionData();
  return userData ? userData.token : null;
};

export const getUserData = (): UserUiModel | null => {
  const userData = getUserSessionData();
  return userData ? userData.user : null;
};

export const isUserAdmin = (user: UserUiModel | null): boolean =>
  user?.role === 'admin';

export const getIsUserAdmin = (): boolean => isUserAdmin(getUserData());
