import { PopulatedLocation, Storage } from '@api/models';
import { SearchFields, SortStorageEnum } from '../../enums';
import { StoragesStoreModel } from '../../models';
import { StorageActionsUnion, StoragesActionTypes } from './actions';

export const initialState: StoragesStoreModel = {
  loading: false,
  locations: {},
  sort: SortStorageEnum.Increase,
  users: {},
  search: {
    field: SearchFields.Client,
    term: ''
  },
  scrollToStorage: {
    locationId: null,
    storageId: null,
    elementPosition: null
  }
};

interface BaseItem {
  _id?: string;
}

const objFromList = <T extends BaseItem>(list: T[]): { [k: string]: T } =>
  list.reduce((acc, curr) => {
    acc[curr._id as string] = curr;
    return acc;
  }, {} as { [locationId: string]: T });

const removeStorage = (storages: Storage[], storageId: string): Storage[] =>
  storages.filter(({ _id }) => storageId !== _id);

export const storagesReducer = (
  state: StoragesStoreModel = initialState,
  action: StorageActionsUnion
): StoragesStoreModel => {
  // console.log('Storage REDUCER ---->', state, action);
  switch (action.type) {
    case StoragesActionTypes.SetLoading:
      return {
        ...state,
        loading: action.payload
      };
    case StoragesActionTypes.SetSort:
      return {
        ...state,
        sort: action.payload
      };
    case StoragesActionTypes.AddStorage:
      return {
        ...state,
        locations: {
          ...state.locations,
          [action.payload.locationId]: {
            ...state.locations[action.payload.locationId],
            storages: [
              ...state.locations[action.payload.locationId].storages,
              action.payload
            ]
          }
        }
      };
    case StoragesActionTypes.UpdateStorage:
      var { payload } = action;
      const allStorages = Object.values(state.locations)
        .map(({ storages }) => storages)
        .flat();
      const currentStorage = allStorages.find(
        ({ _id }) => _id === payload._id
      ) as Storage;
      let storages = [...state.locations[action.payload.locationId].storages];
      const isMovingStorage = currentStorage.locationId !== payload.locationId;

      if (isMovingStorage) {
        const stateLocation = state.locations[
          currentStorage.locationId
        ] as PopulatedLocation;
        // Remove storage from current location
        state.locations[currentStorage.locationId] = {
          ...stateLocation,
          storages: removeStorage(stateLocation.storages, action.payload._id)
        };
        // Add storage to end of location storages list
        storages = [...storages, action.payload];
      } else {
        const storageIndex = storages.findIndex(
          ({ _id }) => action.payload._id === _id
        );
        storages[storageIndex] = action.payload;
      }

      return {
        ...state,
        locations: {
          ...state.locations,
          [action.payload.locationId]: {
            ...state.locations[action.payload.locationId],
            storages
          }
        }
      };
    case StoragesActionTypes.DeleteStorage:
      const locationStorages =
        state.locations[action.payload.locationId].storages;
      return {
        ...state,
        locations: {
          ...state.locations,
          [action.payload.locationId]: {
            ...state.locations[action.payload.locationId],
            storages: removeStorage(locationStorages, action.payload._id)
          }
        }
      };
    case StoragesActionTypes.SetLocations:
      return {
        ...state,
        locations: objFromList(action.payload)
      };
    case StoragesActionTypes.AddLocation:
      return {
        ...state,
        locations: {
          ...state.locations,
          [action.payload._id as string]: {
            ...action.payload,
            storages: []
          }
        }
      };
    case StoragesActionTypes.UpdateLocation:
      const locationId = action.payload._id as string;
      return {
        ...state,
        locations: {
          ...state.locations,
          [locationId]: {
            ...state.locations[locationId],
            address: action.payload.address
          }
        }
      };
    case StoragesActionTypes.DeleteLocation:
      const locations = { ...state.locations };
      delete locations[action.payload];
      return {
        ...state,
        locations
      };
    case StoragesActionTypes.SetUsers:
      return {
        ...state,
        users: objFromList(action.payload)
      };
    case StoragesActionTypes.AddUser:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload._id as string]: action.payload
        }
      };
    case StoragesActionTypes.UpdateUser:
      const user = state.users[action.payload._id as string];
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload._id as string]: {
            ...user,
            ...action.payload
          }
        }
      };
    case StoragesActionTypes.DeleteUser:
      const users = { ...state.users };
      delete users[action.payload];
      return {
        ...state,
        users
      };
    case StoragesActionTypes.SetSearch:
      return {
        ...state,
        search: action.payload
      };
    case StoragesActionTypes.SetScrollTo:
      return {
        ...state,
        scrollToStorage: {
          ...state.scrollToStorage,
          ...action.payload
        }
      };
    default:
      return state;
  }
};
