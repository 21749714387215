import { StorageProps } from '@api/models';
import { DropdownItem } from '../components/shared';
import { StoragePositions } from '../enums';

export const REGION_ITEMS: DropdownItem[] = [
  { label: 'Region 1', value: StoragePositions.Region1 },
  { label: 'Region 2', value: StoragePositions.Region2 },
  { label: 'Region 3', value: StoragePositions.Region3 },
  { label: 'Region 4', value: StoragePositions.Region4 },
  { label: 'Region 5', value: StoragePositions.Region5 },
  { label: 'Region 6', value: StoragePositions.Region6 },
  { label: 'Region 7', value: StoragePositions.Region7 }
];

export const StorageFormFields: {
  [K in Exclude<StorageProps, '_id'>]: K;
} = {
  clientName: 'clientName',
  projectNumber: 'projectNumber',
  depth: 'depth',
  width: 'width',
  size: 'size',
  startDate: 'startDate',
  endDate: 'endDate',
  storedOnCustomer: 'storedOnCustomer',
  notes: 'notes',
  number: 'number',
  locationId: 'locationId',
  position: 'position'
} as const;
