import styled from 'styled-components';

import {
  storageActions,
  StoragesDispatchContext,
  StoragesStateContext
} from '../../state/storages';
import { SortStorageEnum } from '../../enums';
import { Takeover } from '../elements';
import { MenuLink, MenuSection } from '../shared';
import { useContext, useRef } from 'react';

const SortTakeoverStyles = styled.div`
  .section:not(:first-child) {
    margin-top: 0.85rem;
  }
`;

export function SortTakeover() {
  const takeover = useRef(null);
  const dispatch = useContext(StoragesDispatchContext);
  const { sort } = useContext(StoragesStateContext);

  const onLinkClick = (action: SortStorageEnum) => () => {
    dispatch(storageActions.setSort(action));
    (takeover?.current as any).close();
  };

  return (
    <Takeover title="Sort" ref={takeover}>
      <SortTakeoverStyles>
        <MenuSection className="section" title="Portable Storage Location">
          <MenuLink
            label="portable storage on client"
            selected={sort === SortStorageEnum.StoredOnCustomer}
            onClick={onLinkClick(SortStorageEnum.StoredOnCustomer)}
          ></MenuLink>
        </MenuSection>
        <MenuSection className="section" title="Status">
          <MenuLink
            label="available to used"
            selected={sort === SortStorageEnum.AvailableToUsed}
            onClick={onLinkClick(SortStorageEnum.AvailableToUsed)}
          ></MenuLink>
          <MenuLink
            label="used to available"
            selected={sort === SortStorageEnum.UsedToAvailable}
            onClick={onLinkClick(SortStorageEnum.UsedToAvailable)}
          ></MenuLink>
        </MenuSection>
        <MenuSection className="section" title="Numbers">
          <MenuLink
            label="increase"
            selected={sort === SortStorageEnum.Increase}
            onClick={onLinkClick(SortStorageEnum.Increase)}
          ></MenuLink>
          <MenuLink
            label="decrease"
            selected={sort === SortStorageEnum.Decrease}
            onClick={onLinkClick(SortStorageEnum.Decrease)}
          ></MenuLink>
        </MenuSection>
        <MenuSection className="section" title="Storage Size">
          <MenuLink
            label="smallest to largest"
            selected={sort === SortStorageEnum.Smallest}
            onClick={onLinkClick(SortStorageEnum.Smallest)}
          ></MenuLink>
          <MenuLink
            label="largest to smallest"
            selected={sort === SortStorageEnum.Largest}
            onClick={onLinkClick(SortStorageEnum.Largest)}
          ></MenuLink>
        </MenuSection>
      </SortTakeoverStyles>
    </Takeover>
  );
}
