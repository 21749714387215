import styled from 'styled-components';

import { COLORS } from '../../styles';

type SpinnerSizes = 'small' | 'big';

interface SpinnerProps {
  className?: string;
  color?: keyof typeof COLORS;
  size?: SpinnerSizes;
}

const sizes = {
  small: 26,
  big: 48
};

const borderSizes = {
  small: 3,
  big: 4
};

const getBorderSize = (props: SpinnerProps) =>
  borderSizes[props.size || 'small'];

const getSize = (props: SpinnerProps) => sizes[props.size || 'small'];

const getColor = (props: SpinnerProps) => COLORS[props.color || 'white'];

const SpinnerStyles = styled.span`
  .spinner {
    display: inline-block;
    position: relative;
    width: ${getSize}px;
    height: ${getSize}px;

    span {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: ${getSize}px;
      height: ${getSize}px;
      margin: ${getBorderSize}px;
      border: ${getBorderSize}px solid ${getColor};
      border-radius: 50%;
      animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: ${getColor} transparent transparent transparent;
    }
    span:nth-child(1) {
      animation-delay: -0.45s;
    }
    span:nth-child(2) {
      animation-delay: -0.3s;
    }
    span:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export function Spinner(props: SpinnerProps) {
  return (
    <SpinnerStyles {...props}>
      <span className="spinner">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </span>
    </SpinnerStyles>
  );
}
