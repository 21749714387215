import { useContext, useMemo } from 'react';

import { DropdownItem } from '@client/components/shared';
import { StoragesStateContext } from './contexts';

export const useGetLocationsOptions = (): DropdownItem[] => {
  const { locations } = useContext(StoragesStateContext);
  return useMemo(() => {
    return Object.entries(locations).map(([locationId, { address }]) => ({
      value: locationId,
      label: address
    }));
  }, [locations]);
};
