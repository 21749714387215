import { StoragePositions } from '../enums';

export const COLORS = {
  black: '#1D1D1D',
  blue: '#4180FF',
  grey: '#6e6e6e',
  greyLight: '#eaeaea',
  greyLighter: '#F4F4F4',
  greyLightDarker: '#DCDCDC',
  greyMedium: '#B0B0B0',
  shadow: 'rgba(157, 157, 157, 0.4)',
  green: '#5BDA7C',
  greenDarker: '#51BB6C',
  orange: '#FF8B00',
  red: '#F36B59',
  white: '#FFFFFF'
};

export const POSITION_COLORS = {
  [StoragePositions.Front]: COLORS.orange,
  [StoragePositions.Back]: COLORS.blue
};

export const REGION_COLORS: { [k: string]: string } = {
  [StoragePositions.Region1]: '#FFB800',
  [StoragePositions.Region2]: '#735EF8',
  [StoragePositions.Region3]: '#1FC98C',
  [StoragePositions.Region4]: '#FF5D5D',
  [StoragePositions.Region5]: '#2C6EF4',
  [StoragePositions.Region6]: '#00E0E0',
  [StoragePositions.Region7]: '#E04BD2'
};
