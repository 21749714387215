import styled from 'styled-components';

import { COLORS } from '../../styles';

export const Textarea = styled.textarea`
  appearance: none;
  background-color: ${(props) =>
    props.disabled ? COLORS.greyLighter : '#fff'};
  border-radius: 10px;
  border: 1px solid ${COLORS.greyLight};
  box-shadow: 0 0.25em ${COLORS.greyLight};
  color: ${COLORS.black};
  font-size: 1.15rem;
  padding: 0.55rem 0.75rem;
  width: 100%;
  resize: none;

  ::placeholder {
    color: ${COLORS.grey};
  }

  :disabled {
    color: ${COLORS.grey};
  }

  :focus {
    border-color: ${COLORS.greyMedium};
    outline: 0;
  }

  :-webkit-autofill {
    &,
    &:hover,
    &:focus,
    &:active {
      /* The first shadow is to avoid getting the input with blue background when being autocompleted */
      box-shadow: 0 0 0px 1000px #ffffff inset, 0 0.25em ${COLORS.greyLight};
    }
  }

  ::-webkit-scrollbar {
    width: 0.3em;
  }

  ::-webkit-scrollbar-track {
    margin: 4px 0;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0.2em;
    background-color: ${COLORS.greyLight};
  }
`;
