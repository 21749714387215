import { MouseEventHandler } from 'react';
import styled from 'styled-components';

import { COLORS, MEDIAS } from '../../styles';
import { CloseIcon } from '../icons';
import { H2 } from './H2';
import { IconButton } from './IconButton';

const TakeoverHeaderStyles = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1.25rem 1.25rem;

  &:not(.no-shadow) {
    box-shadow: 0px -6px 20px ${COLORS.shadow};
  }

  .title {
    flex-grow: 1;

    &--orange {
      color: ${COLORS.orange};
    }
  }

  .icon {
    width: 1rem;
  }

  .subtitle {
    color: ${COLORS.grey};
    flex-basis: 100%;
    font-size: 0.875rem;
    margin: 0.5rem 0 0;
  }

  @media ${MEDIAS.large} {
    box-shadow: none !important;
    border-bottom: 3px solid ${COLORS.greyLighter};
    padding: 0 0 1rem;
  }
`;

export interface TakeOverProps {
  title: string;
  titleColor?: keyof typeof COLORS;
  subtitle?: string;
  onClose?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

export function TakeoverHeader(props: TakeOverProps) {
  let titleClassName = 'title';
  if (props.titleColor) titleClassName += ` title--${props.titleColor}`;

  return (
    <TakeoverHeaderStyles className={props.className}>
      <H2 className={titleClassName}>{props.title}</H2>
      {props.onClose && (
        <IconButton onClick={props.onClose}>
          <CloseIcon className="icon"></CloseIcon>
        </IconButton>
      )}
      {props.subtitle && <p className="subtitle">{props.subtitle}</p>}
    </TakeoverHeaderStyles>
  );
}
