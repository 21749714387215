import { useState } from 'react';
import styled from 'styled-components';

import { COLORS } from '../../styles';
import { DeleteIcon, EditIcon } from '../icons';
import { Button } from './Button';
import { H2 } from './H2';

interface BasicItem {
  _id?: string;
}

interface EditingListProps<T extends BasicItem> {
  list: T[];
  onCancel: () => void;
  onEdit: (item: T) => void;
  onDelete: (item: T) => void;
  children: {
    title: string;
    itemLabel: (item: T) => string;
    emptyMessage?: string;
  };
  className?: string;
}

const EditingListStyles = styled.div`
  border-top: 5px solid ${COLORS.greyLight};
  margin-top: 2rem;
  padding-top: 1rem;

  .list-item {
    align-items: center;
    color: ${COLORS.grey};
    display: flex;
    font-size: 1.1rem;
    padding: 0.5rem 0;
    min-height: 60px;

    &:not(:last-child) {
      border-bottom: 1px solid ${COLORS.greyLight};
    }

    &.on-edit {
      color: ${COLORS.black};
    }
  }

  .list-item__icon {
    margin-left: 0.5rem;
    transition: 0.3s opacity ease-in-out;

    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }

  .list-item__actions {
    margin-left: auto;
  }

  .list-item__button {
    font-size: 0.75rem;
  }
`;

export function EditingList<T extends BasicItem>(props: EditingListProps<T>) {
  const [editId, setEditId] = useState<string | undefined>();

  const onEdit = (item: T) => {
    setEditId(item._id);
    props.onEdit(item);
  };

  const onCancel = () => {
    setEditId(undefined);
    props.onCancel();
  };

  return (
    <EditingListStyles className={props.className}>
      <H2 color="black">{props.children.title}</H2>
      {props.list.length ? (
        props.list.map((item) => (
          <div
            className={`list-item ${editId === item._id ? 'on-edit' : ''}`}
            key={item._id}
          >
            {props.children.itemLabel(item)}
            <div className="list-item__actions">
              {editId === item._id ? (
                <Button
                  className="list-item__button"
                  styleType="secondary"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              ) : (
                <>
                  <EditIcon
                    className="list-item__icon"
                    onClick={() => onEdit(item)}
                  />
                  <DeleteIcon
                    className="list-item__icon"
                    onClick={() => props.onDelete(item)}
                  />
                </>
              )}
            </div>
          </div>
        ))
      ) : (
        <p>{props.children.emptyMessage || ''}</p>
      )}
    </EditingListStyles>
  );
}
