import { useContext, useRef, useState } from 'react';
import styled from 'styled-components';

import { SearchFields } from '../../enums';
import { SearchModel } from '../../models';
import {
  storageActions,
  StoragesDispatchContext,
  StoragesStateContext
} from '../../state/storages';
import { COLORS } from '../../styles';
import { Takeover } from '../elements';
import { Button, Input, RadioGroup, RadioItem } from '../shared';

const SearchTakeoverStyles = styled.div`
  .search-input,
  .search-button {
    width: 100%;
    margin: 0.85rem 0;
  }
`;

const fieldItems: RadioItem[] = [
  {
    label: 'Client',
    value: SearchFields.Client,
    name: SearchFields.Client,
    color: COLORS.blue
  },
  {
    label: 'Number',
    value: SearchFields.Number,
    name: SearchFields.Number,
    color: COLORS.blue
  },
  {
    label: 'Project',
    value: SearchFields.Project,
    name: SearchFields.Project,
    color: COLORS.blue
  }
];

export function SearchTakeover() {
  const takeover = useRef(null);
  const dispatch = useContext(StoragesDispatchContext);
  const {
    search: { field, term }
  } = useContext(StoragesStateContext);

  const [formState, setFormState] = useState<SearchModel>({
    field,
    term
  });

  const closeTakeover = () => (takeover?.current as any).close();

  const handleChange = (e: any) => {
    const { target } = e;
    setFormState({
      ...formState,
      [target.name]: target.value
    });
  };

  const onSubmit = () => {
    dispatch(storageActions.setSearch(formState));
    closeTakeover();
  };

  return (
    <Takeover title="Search" ref={takeover}>
      <SearchTakeoverStyles>
        <p>Search for Storage Number, Client Name or Project Number</p>
        <RadioGroup
          size="small"
          name="field"
          items={fieldItems}
          value={formState.field}
          onChange={handleChange}
        ></RadioGroup>
        <Input
          className="search-input"
          name="term"
          placeholder="Add name, number or project"
          value={formState.term}
          onChange={handleChange}
        />
        <Button className="search-button" onClick={onSubmit}>
          search
        </Button>
      </SearchTakeoverStyles>
    </Takeover>
  );
}
