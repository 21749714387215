import { useContext, useRef, useState } from 'react';
import styled from 'styled-components';

import { PopulatedLocation } from '@api/models';
import { MAIN_PATH } from '../../constants';
import { LocationsHttpService } from '../../services';
import {
  getLocationsListFromStore,
  storageActions,
  StoragesDispatchContext,
  StoragesStateContext
} from '../../state/storages';
import { Takeover } from '../elements';
import { AddLocationForm, FormAddLocation } from '../forms';
import { Message } from '../shared';

const TakeoverStyles = styled.div`
  .location-address {
    display: inline-block;
    font-size: 1.15rem;
    padding: 0.5rem;
  }
`;

export function AddLocationTakeover() {
  const takeover = useRef(null);
  const dispatch = useContext(StoragesDispatchContext);
  const locations = getLocationsListFromStore(useContext(StoragesStateContext));
  const [confirmationMsg, setConfirmationMsg] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [locationToDelete, setLocationToDelete] =
    useState<PopulatedLocation | null>(null);
  const exitTo = `/${MAIN_PATH}`;
  let saving = false;

  const closeTakeover = () => (takeover?.current as any).close();

  const onSubmit = async (formData: FormAddLocation) => {
    if (saving) {
      return;
    }
    saving = true;

    if (formData._id) {
      // Editing Location
      const result = await LocationsHttpService.updateLocation(
        formData._id,
        formData.address
      );
      if (result.status === 200) {
        setConfirmationMsg('Location Edited Successfully!');
        dispatch(storageActions.updateLocation(result.data));
      }
    } else {
      // Adding Location
      const result = await LocationsHttpService.addLocation(formData.address);
      if (result.status === 201) {
        setConfirmationMsg('New Location Completed with Success');
        dispatch(storageActions.addLocation(result.data));
      }
    }
  };

  const onDeleteConfirm = async () => {
    if (saving || !locationToDelete) {
      return;
    }
    saving = true;

    try {
      const result = await LocationsHttpService.deleteLocation(
        locationToDelete._id as string
      );
      if (result.status === 200) {
        setConfirmationMsg('Location Deleted Successfully!');
        dispatch(storageActions.deleteLocation(result.data));
      } else {
        throw result;
      }
    } catch (error: any) {
      setErrorMsg(error.response.data.error);
    } finally {
      saving = false;
    }
  };

  const onCancel = () => {
    setLocationToDelete(null);
    setErrorMsg('');
  };

  return (
    <TakeoverStyles>
      <Takeover
        fullScreen
        title="Register Location"
        subtitle="Add address of new location"
        ref={takeover}
        fixedContent={!!locationToDelete}
        exitTo={exitTo}
      >
        {confirmationMsg ? (
          <Message
            title={confirmationMsg}
            onConfirm={closeTakeover}
            actionLabel="go to dashboard"
            successImg
          />
        ) : (
          <>
            <AddLocationForm
              locations={locations}
              onSubmit={onSubmit}
              onDelete={setLocationToDelete}
            />
            {locationToDelete && (
              <Message
                title="Are you sure?"
                onConfirm={onDeleteConfirm}
                onCancel={onCancel}
                errorMsg={errorMsg}
                actionLabel="delete"
                deleteImg
                absolute
              >
                The location
                <strong className="location-address">
                  {locationToDelete?.address}
                </strong>
                will be permanently deleted.
              </Message>
            )}
          </>
        )}
      </Takeover>
    </TakeoverStyles>
  );
}
