import axios from 'axios';

import { LOGGED_USER_KEY } from '../constants';

let registered: boolean;

export const registerResponseInterceptor = () => {
  if (registered) {
    return;
  }

  registered = true;

  axios.interceptors.response.use(undefined, function (error) {
    const { status } = error.response;
    if (status === 401) {
      window.sessionStorage.removeItem(LOGGED_USER_KEY);
      window.location.href = '/';
    }
    return error;
  });
};
