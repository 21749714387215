import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import { COLORS } from '../../styles';
import { ArrowIcon } from '../icons';

const DropdownStyles = styled.div`
  width: 100%;
  position: relative;

  .select-container,
  .select-menu {
    background-color: white;
    border: 1px solid ${COLORS.greyLight};
    border-radius: 10px;
  }

  .select-container {
    cursor: pointer;
    appearance: none;
    box-shadow: 0 0.25em ${COLORS.greyLight};
    padding: 0.8rem 1.8rem 0.8rem 0.8rem;
    width: 100%;

    &.has-error {
      border-color: ${COLORS.red};
    }
  }

  .select-icon {
    display: block;
    transform: rotate(90deg);
    position: absolute;
    top: calc(50% - 5px);
    right: 1rem;
  }

  .select-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 0.5rem;
    box-shadow: 0px 6px 10px rgba(218, 218, 218, 0.5);
    display: none;
    z-index: 10;
    max-height: 210px;
    overflow-y: auto;

    &.is-open {
      display: block;
    }

    &__item {
      cursor: pointer;
      border-radius: 10px;
      padding: 0.65rem 0.9rem;
      line-height: 1;

      &:hover,
      &.is-selected {
        background: ${COLORS.greyLighter};
      }
    }
  }
`;

export interface DropdownItem {
  value: string;
  label: string;
}

export interface DropDownItemComponentProps extends DropdownItem {
  selected?: boolean;
}
export type DropDownItemComponent = (
  props: DropDownItemComponentProps
) => JSX.Element;

export interface DropdownProps {
  name: string;
  placeholder: string;
  value: string;
  items: DropdownItem[];
  onChange: React.ReactEventHandler<HTMLDivElement>;
  hasError?: boolean;
  itemComponent?: DropDownItemComponent;
  valueComponent?: DropDownItemComponent;
}

export function Dropdown(props: DropdownProps) {
  const [isOpen, setIsOpen] = useState(false);

  const onOutsideClick = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    document.addEventListener('click', onOutsideClick);

    return () => {
      document.removeEventListener('click', onOutsideClick);
    };
  }, [onOutsideClick]);

  const onClick: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const onItemClick =
    (value: string) => (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      props.onChange({
        ...e,
        target: { ...e.target, name: props.name, value }
      } as any);
      setIsOpen(false);
    };

  const getValueLabel = (value: string): string =>
    props.items.find((item) => item.value === value)?.label || '';
  const currValueLabel = getValueLabel(props.value);

  return (
    <DropdownStyles>
      <div
        className={`select-container no-tap-highlight ${
          props.hasError ? 'has-error' : ''
        }`}
        onClick={onClick}
      >
        {props.value ? (
          props.valueComponent ? (
            <props.valueComponent value={props.value} label={currValueLabel} />
          ) : (
            currValueLabel
          )
        ) : (
          props.placeholder
        )}
      </div>
      <ArrowIcon className="select-icon" color={isOpen ? 'blue' : 'black'} />
      <div className={`select-menu ${isOpen ? 'is-open' : ''}`}>
        {props.items.map((item) => (
          <div
            className={`select-menu__item no-tap-highlight ${
              item.value === props.value ? 'is-selected' : ''
            }`}
            key={item.value}
            onClick={onItemClick(item.value)}
          >
            {props.itemComponent ? (
              <props.itemComponent
                value={item.value}
                label={item.label}
                selected={item.value === props.value}
              />
            ) : (
              item.label
            )}
          </div>
        ))}
      </div>
    </DropdownStyles>
  );
}
