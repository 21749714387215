import {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { useHistory } from 'react-router-dom';

import { COLORS, MEDIAS } from '../../styles';
import { TakeoverHeader } from '../shared';

const TakeoverStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition: background 0.3s ease-in-out;

  .container {
    z-index: 2;
    background: white;
    width: 100%;
    max-height: 100%;
    bottom: 0;
    position: absolute;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease-in, opacity 0.3s;
    transform: translate3d(0, 100%, 0);
  }

  .header {
    margin: -1rem 0 1rem;
  }

  .content {
    padding: 0 1.2rem;

    &:not(.is-fixed-content) {
      overflow-x: hidden;
      overflow-y: auto;
    }

    &::-webkit-scrollbar {
      width: 0.4em;
    }

    &::-webkit-scrollbar-track {
      margin: 4px 0;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.2em;
      background-color: ${COLORS.greyLight};
    }
  }

  &.takeover-enter,
  &.takeover-exit-active,
  &.takeover-exit-done {
    background: rgba(255, 255, 255, 0);

    .container {
      transform: translate3d(0, 100%, 0);
      opacity: 0.5;
    }
  }
  &.takeover-enter-active,
  &.takeover-enter-done {
    background: rgba(255, 255, 255, 0.85);

    .container {
      transform: translate3d(0, 0, 0);
    }
  }

  &.is-full-screen {
    .container {
      height: 100%;
    }

    .content {
      flex-grow: 1;
      position: relative;
    }
  }

  @media ${MEDIAS.large} {
    .container {
      box-shadow: 0px -6px 50px ${COLORS.shadow};
      max-width: 570px;
      height: 100%;
      right: 0;
      padding: 3rem 2.75rem 1.5rem 3rem;
      transition: transform 0.45s cubic-bezier(0.67, 0.05, 0.51, 0.88),
        opacity 0.3s;
    }

    .content {
      padding: 0;
      width: calc(100% + 12px);
      padding-right: 15px;
      margin-right: -12px;
    }

    &.takeover-enter,
    &.takeover-exit-active,
    &.takeover-exit-done {
      .container {
        transform: translate3d(100%, 0, 0);
        opacity: 1;
      }
    }
  }
`;

export interface TakeoverProps {
  title: string;
  titleColor?: keyof typeof COLORS;
  subtitle?: string;
  fullScreen?: boolean;
  exitTo?: string;
  onClose?: () => void;
  fixedContent?: boolean;
  noCloseNavigation?: boolean;
}

export const Takeover = forwardRef<
  HTMLElement,
  PropsWithChildren<TakeoverProps>
>((props, ref: any) => {
  const history = useHistory();
  const nodeRef = useRef(null);
  const [shouldRender, setShouldRender] = useState(false);

  useImperativeHandle(ref, () => ({
    close: () => {
      onClose();
    }
  }));

  useEffect(() => {
    setShouldRender(true);
  }, []);

  const onClose = () => {
    setShouldRender(false);
  };

  const onExited = () => {
    if (!props.noCloseNavigation) {
      if (props.exitTo) {
        history.push(props.exitTo);
      } else {
        history.goBack();
      }
    }
    props.onClose && props.onClose();
  };

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={shouldRender}
      classNames="takeover"
      timeout={350}
      mountOnEnter
      unmountOnExit
      onExited={onExited}
    >
      <TakeoverStyles
        ref={nodeRef}
        className={props.fullScreen ? 'is-full-screen' : ''}
      >
        <div className="container">
          <div className="header">
            <TakeoverHeader
              title={props.title}
              titleColor={props.titleColor}
              subtitle={props.subtitle}
              onClose={onClose}
            />
          </div>
          <div
            className={`content ${
              props.fixedContent ? 'is-fixed-content' : ''
            }`}
          >
            {props.children}
          </div>
        </div>
      </TakeoverStyles>
    </CSSTransition>
  );
});
