import { COLORS } from '../../styles';
import { IconProps } from '../../models';

export function ArrowIcon(props: IconProps) {
  const fill = props.color ? { fill: COLORS[props.color] } : {};
  return (
    <svg
      className={props.className}
      width={props.width || 8}
      height={props.height || 12}
      viewBox="0 0 8 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        {...fill}
        className="transition-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.6407 5.23526L1.6407 0.231455C1.21627 -0.122718 0.585305 -0.0655012 0.231401 0.359252C-0.122503 0.784006 -0.0653301 1.41545 0.359101 1.76962L5.4375 6.00454L0.359101 10.2393C-0.0545627 10.5961 -0.105939 11.2189 0.243658 11.6388C0.593255 12.0587 1.21468 12.1204 1.64 11.7776L7.64 6.77383C7.86775 6.58338 7.99937 6.30166 7.99937 6.00464C7.99937 5.70762 7.86775 5.4259 7.64 5.23546L7.6407 5.23526Z"
      />
    </svg>
  );
}
