import {
  FormEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState
} from 'react';
import styled from 'styled-components';

import { COLORS, MEDIAS } from '../../styles';
import { ErrorMessage } from './ErrorMessage';
import { Input } from './Input';
import { Button } from './Button';
import { Spinner } from './Spinner';

const FormStyles = styled.div`
  height: 100%;

  .error-message {
    margin-bottom: 1rem;
  }

  .form {
    display: flex;
    flex-direction: column;
    height: 100%;
    row-gap: 1rem;
  }

  .form-content {
    display: flex;
    width: calc(100% + 14px);
    flex-wrap: wrap;
    align-content: flex-start;
    flex-grow: 1;
    column-gap: 4%;
    padding-right: 10px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.4em;
    }

    &::-webkit-scrollbar-track {
      margin: 4px 0;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.2em;
      background-color: ${COLORS.greyLight};
    }
  }

  .form-footer {
    padding-bottom: 5px;
  }

  .form-label {
    display: block;
    padding-bottom: 0.5rem;
  }

  .form-col {
    width: 48%;
    padding: 0.5rem 0;

    &.content-right {
      display: flex;
      justify-content: flex-end;
    }

    &.is-full {
      width: 100%;
    }

    &__text {
      padding: 0 0.35rem;
    }

    &__fields-wrap {
      align-items: center;
      display: flex;

      & > ${Input} {
        // Prevents input element from overflowing parents size
        min-width: 0;
      }
    }
  }

  .form-icon {
    cursor: pointer;
    margin-left: 1rem;
    stroke: ${COLORS.blue};
  }

  ${Input}, .form-button {
    width: 100%;
    max-width: 100%;
  }
`;

export interface FormProps {
  submitLabel: string;
  isLoading?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  onSubmit?: FormEventHandler<HTMLFormElement> | undefined;
  onErrorHide?: () => void;
}

export function Form(props: PropsWithChildren<FormProps>) {
  const [showError, setShowError] = useState<boolean | undefined>(false);
  const { onErrorHide } = props;

  const hideErrorCb = useCallback(() => {
    setShowError(false);
    onErrorHide && onErrorHide();
  }, [onErrorHide]);

  useEffect(() => {
    if (props.hasError) {
      setShowError(true);
    }
  }, [props.hasError, props.errorMessage]);

  return (
    <FormStyles>
      {showError && props.hasError && (
        <ErrorMessage className="error-message" onExited={hideErrorCb}>
          {props.errorMessage || (
            <>
              You need to add all the information.
              <br /> Check the fields in red!
            </>
          )}
        </ErrorMessage>
      )}
      <form className="form" onSubmit={props.onSubmit}>
        <div className="form-content">{props.children}</div>
        <div className="form-footer">
          <Button className="form-button" type="submit">
            {props.isLoading ? <Spinner /> : props.submitLabel}
          </Button>
        </div>
      </form>
    </FormStyles>
  );
}
