import styled from 'styled-components';

import { COLORS } from '../../styles';

export const IconButton = styled.button`
  align-items: center;
  justify-content: center;
  background: none;
  border-radius: 10px;
  border: 1px solid ${COLORS.blue};
  box-sizing: content-box;
  display: flex;
  fill: ${COLORS.blue};
  min-height: 1.75rem;
  min-width: 1.75rem;
  padding: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;
