import styled from 'styled-components';

import { REGION_COLORS } from '../../styles';
import { DropDownItemComponent } from './Dropdown';

const RegionItemStyles = styled.div`
  display: flex;
  align-items: center;

  .region-color {
    background: black;
    border-radius: 10px;
    width: 40px;
    height: 20px;
    margin-right: 20px;
  }
`;

export const RegionItem: DropDownItemComponent = (props) => (
  <RegionItemStyles>
    <span
      className="region-color"
      style={{ backgroundColor: REGION_COLORS[props.value] }}
    ></span>{' '}
    {props.label}
  </RegionItemStyles>
);
