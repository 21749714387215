import {
  Storage,
  PopulatedLocation,
  LocationModel,
  UserModel
} from '@api/models';

import { Action, ScrollToStorageModel, SearchModel } from '../../models';
import { SortStorageEnum } from '../../enums';
import { union } from '../../utils';

export enum StoragesActionTypes {
  Set = '[Storages] Set',
  SetLoading = '[Storages] Set loading',
  SetSort = '[Storages] Set Sort',
  AddStorage = '[Storages] Add Storage',
  UpdateStorage = '[Storages] Update Storage',
  DeleteStorage = '[Storages] Delete Storage',
  SetLocations = '[Storages] Set Locations',
  AddLocation = '[Storages] Add Location',
  UpdateLocation = '[Storages] Update Location',
  DeleteLocation = '[Storages] Delete Location',
  SetUsers = '[Storages] Set Users',
  AddUser = '[Storages] Add User',
  UpdateUser = '[Storages] Update User',
  DeleteUser = '[Storages] Delete User',
  SetSearch = '[Storages] Set Search',
  SetScrollTo = '[Storages] Set Scroll To'
}

export const setLoading: Action<StoragesActionTypes.SetLoading, boolean> = (
  payload
) => ({
  type: StoragesActionTypes.SetLoading,
  payload
});

export const setStorages: Action<StoragesActionTypes.Set, Storage[]> = (
  payload
) => ({
  type: StoragesActionTypes.Set,
  payload
});

export const setSort: Action<
  StoragesActionTypes.SetSort,
  SortStorageEnum | null
> = (payload) => ({
  type: StoragesActionTypes.SetSort,
  payload
});

export const addStorage: Action<StoragesActionTypes.AddStorage, Storage> = (
  payload
) => ({
  type: StoragesActionTypes.AddStorage,
  payload
});

export const updateStorage: Action<StoragesActionTypes.UpdateStorage, Storage> =
  (payload) => ({
    type: StoragesActionTypes.UpdateStorage,
    payload
  });

export const deleteStorage: Action<StoragesActionTypes.DeleteStorage, Storage> =
  (payload) => ({
    type: StoragesActionTypes.DeleteStorage,
    payload
  });

export const setLocations: Action<
  StoragesActionTypes.SetLocations,
  PopulatedLocation[]
> = (payload) => ({
  type: StoragesActionTypes.SetLocations,
  payload
});

export const addLocation: Action<
  StoragesActionTypes.AddLocation,
  LocationModel
> = (payload) => ({
  type: StoragesActionTypes.AddLocation,
  payload
});

export const updateLocation: Action<
  StoragesActionTypes.UpdateLocation,
  LocationModel
> = (payload) => ({
  type: StoragesActionTypes.UpdateLocation,
  payload
});

export const deleteLocation: Action<
  StoragesActionTypes.DeleteLocation,
  string
> = (payload) => ({
  type: StoragesActionTypes.DeleteLocation,
  payload
});

export const setUsers: Action<StoragesActionTypes.SetUsers, UserModel[]> = (
  payload
) => ({
  type: StoragesActionTypes.SetUsers,
  payload
});

export const addUser: Action<StoragesActionTypes.AddUser, UserModel> = (
  payload
) => ({
  type: StoragesActionTypes.AddUser,
  payload
});

export const updateUser: Action<StoragesActionTypes.UpdateUser, UserModel> = (
  payload
) => ({
  type: StoragesActionTypes.UpdateUser,
  payload
});

export const deleteUser: Action<StoragesActionTypes.DeleteUser, string> = (
  payload
) => ({
  type: StoragesActionTypes.DeleteUser,
  payload
});

export const setSearch: Action<StoragesActionTypes.SetSearch, SearchModel> = (
  payload
) => ({
  type: StoragesActionTypes.SetSearch,
  payload
});

export const setScrollTo: Action<
  StoragesActionTypes.SetScrollTo,
  ScrollToStorageModel
> = (payload) => ({
  type: StoragesActionTypes.SetScrollTo,
  payload
});

export const storageActions = {
  setLoading,
  setStorages,
  setSort,
  addStorage,
  updateStorage,
  deleteStorage,
  setLocations,
  addLocation,
  updateLocation,
  deleteLocation,
  setUsers,
  addUser,
  updateUser,
  deleteUser,
  setSearch,
  setScrollTo
};

const storageActionsUnion = union(storageActions);
export type StorageActionsUnion = typeof storageActionsUnion;
