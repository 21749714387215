import { COLORS } from '../../styles';
import { IconProps } from '../../models';

export function CheckIcon(props: IconProps) {
  const color = COLORS[props.color || 'white'];
  return (
    <svg
      className={props.className}
      width={props.width || 24}
      height={props.height || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 22H6C3.8 22 2 20.2 2 18V6C2 3.8 3.8 2 6 2H18C20.2 2 22 3.8 22 6V18C22 20.2 20.2 22 18 22Z"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={color}
      />
      <path
        d="M8.5 11.8L11.3 14.7L17 9"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={color}
      />
    </svg>
  );
}
