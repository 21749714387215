import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { COLORS } from '../../styles';
import { H2 } from './H2';
import { Button } from './Button';
import { CSSTransition } from 'react-transition-group';
import { ErrorMessage } from './ErrorMessage';

const MessageStyles = styled.div`
  background-color: ${COLORS.white};
  padding-bottom: 3rem;
  margin-bottom: 1rem;
  transition: transform 0.2s ease-in-out, opacity 0.3s, background 0.3s;

  &.is-absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
    margin: 0;
  }

  .message__error {
    margin: 0 1rem;
  }

  .message__content {
    padding: 2rem 2rem 0;
    display: flex;
    /* background: rgba(0, 0, 0, 0.1); */

    &-delete-img {
      flex-grow: 1;
      margin: -6rem 0 -4rem -3rem;

      img {
        display: block;
        width: 140px;
        height: auto;
      }
    }

    &-success-img {
      width: calc(100% + 4rem);
      margin: -2rem -2rem 2rem;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  .message__title {
    padding-bottom: 2rem;
  }

  .message__text {
    color: ${COLORS.black};
    padding-bottom: 1rem;
  }

  .message__actions {
    padding: 0 1.25rem;
  }

  .message__button {
    width: 100%;
    margin-top: 1rem;
  }

  &.message-enter {
    background: rgba(255, 255, 255, 0);
    opacity: 0;
    transform: translate3d(0, 30%, 0);
  }
  &.message-exit-active,
  &.message-exit-done {
    opacity: 0;
  }
  &.message-enter-active,
  &.message-enter-done {
    background: rgba(255, 255, 255, 1);
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export interface MessageProps {
  title: string;
  actionLabel: string;
  onConfirm: () => void;
  onCancel?: () => void;
  className?: string;
  cancelLabel?: string;
  absolute?: boolean;
  deleteImg?: boolean;
  successImg?: boolean;
  errorMsg?: string;
}

export function Message(props: PropsWithChildren<MessageProps>) {
  const nodeRef = useRef(null);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    setShouldRender(true);
  }, []);

  const onCancel = () => {
    setShouldRender(false);
  };

  const onExited = () => {
    props.onCancel && props.onCancel();
  };

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={shouldRender}
      classNames="message"
      timeout={200}
      mountOnEnter
      unmountOnExit
      onExited={onExited}
    >
      <MessageStyles
        ref={nodeRef}
        className={`${props.className || ''} ${
          props.absolute ? 'is-absolute' : ''
        }`}
      >
        {props.errorMsg && (
          <div className="message__error">
            <ErrorMessage>{props.errorMsg}</ErrorMessage>
          </div>
        )}
        <div className="message__content">
          {props.deleteImg && (
            <div className="message__content-delete-img">
              <img src="/svg/delete-illustration.svg" alt={props.title} />
            </div>
          )}
          <div className="message__content-wrapper">
            {props.successImg && (
              <div className="message__content-success-img">
                <img src="/svg/success-illustration.svg" alt={props.title} />
              </div>
            )}
            <H2 className="message__title">{props.title}</H2>
            {props.children && (
              <div className="message__text">{props.children}</div>
            )}
          </div>
        </div>
        <div className="message__actions">
          {props.onCancel && (
            <Button
              className="message__button"
              styleType="secondary"
              onClick={onCancel}
            >
              {props.cancelLabel || 'cancel'}
            </Button>
          )}
          <Button className="message__button" onClick={props.onConfirm}>
            {props.actionLabel}
          </Button>
        </div>
      </MessageStyles>
    </CSSTransition>
  );
}
