import styled from 'styled-components';

import { COLORS } from '../../styles';
import { H2 } from './H2';
import { IconButton } from './IconButton';
import { FilterIcon, SearchIcon, UserIcon } from '../icons';

const PageHeaderStyles = styled.div`
  background: white;
  /* box-shadow: 0px -6px 20px ${COLORS.shadow}; */
  padding: 0.75rem 1.25rem;

  .wrapper {
    align-items: center;
    display: flex;
  }

  .title {
    flex-grow: 1;
  }

  .text {
    color: ${COLORS.grey};
    font-size: 0.75rem;
    margin: 0;
    padding-top: 0.75rem;
  }

  .icon {
    width: 1.3rem;
  }

  .icon-btn {
    margin-left: 0.3rem;
  }
`;

export enum PageHeaderActions {
  Search = 'search',
  Sort = 'sort',
  User = 'user'
}

export interface PageProps {
  title: string;
  text?: string;
  onAction: (action: PageHeaderActions) => void;
}

export function PageHeader(props: PageProps) {
  function action(action: PageHeaderActions) {
    return () => props.onAction(action);
  }

  return (
    <PageHeaderStyles>
      <div className="wrapper">
        <H2 className="title">{props.title}</H2>
        <IconButton
          className="icon-btn"
          onClick={action(PageHeaderActions.Search)}
        >
          <SearchIcon className="icon"></SearchIcon>
        </IconButton>
        <IconButton
          className="icon-btn"
          onClick={action(PageHeaderActions.Sort)}
        >
          <FilterIcon className="icon"></FilterIcon>
        </IconButton>
        <IconButton
          className="icon-btn"
          onClick={action(PageHeaderActions.User)}
        >
          <UserIcon className="icon"></UserIcon>
        </IconButton>
      </div>
      {props.text && <p className="text">{props.text}</p>}
    </PageHeaderStyles>
  );
}
