import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import { COLORS } from '../../styles';

const transitionTime = 400;

export const ErrorMessageStyles = styled.div`
  background-color: ${COLORS.red};
  color: ${COLORS.white};
  font-size: 0.85rem;
  text-align: center;
  transition: max-height ${transitionTime}ms ease-in-out,
    opacity ${transitionTime}ms ease-in-out;
  overflow: hidden;

  .message-wrapper {
    padding: 1.25rem;
  }

  &.error-message-enter {
    max-height: 0;
    padding-bottom: 0;
    opacity: 0;
  }
  &.error-message-enter-active,
  &.error-message-exit {
    max-height: 200px;
    opacity: 1;
  }

  /* &.error-message-exit {
    max-height: 200px;
    opacity: 1;
  } */

  &.error-message-exit-active {
    max-height: 0;
    opacity: 0;
  }
`;

interface ErrorMessageProps {
  className?: string;
  onExited?: () => void;
  autoHideDelay?: number;
}

export const ErrorMessage = (props: PropsWithChildren<ErrorMessageProps>) => {
  const nodeRef = useRef(null);
  const [shouldRender, setShouldRender] = useState<boolean | undefined>(false);
  const defaultTime = 5000;
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onExited = () => {
    props.onExited && props.onExited();
  };

  const tryCleanTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    setShouldRender(true);
    tryCleanTimeout();

    if (typeof props.autoHideDelay !== 'number' || props.autoHideDelay > 0) {
      timeoutRef.current = setTimeout(() => {
        setShouldRender(false);
      }, props.autoHideDelay || defaultTime);
    }

    return tryCleanTimeout;
  }, [props]);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={shouldRender}
      classNames="error-message"
      timeout={transitionTime}
      mountOnEnter={true}
      unmountOnExit={true}
      onExited={onExited}
    >
      <ErrorMessageStyles ref={nodeRef}>
        <div className="message-wrapper">{props.children}</div>
      </ErrorMessageStyles>
    </CSSTransition>
  );
};
