import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { COLORS } from '../../styles';

const ButtonStyled = styled.button`
  background: white;
  border-radius: 10px;
  border: 1px solid ${COLORS.greyLight};
  box-shadow: 0 0.25em ${COLORS.greyLight};
  color: ${COLORS.black};
  cursor: pointer;
  font-size: 1.25rem;
  padding: 0.85em 1.25em;
  position: relative;

  &:hover {
    border-color: ${COLORS.greyMedium};
  }

  &:focus {
    box-shadow: 0 0.1rem ${COLORS.greyLight};
    top: 0.15rem;
  }

  &.primary {
    color: white;
    background: ${COLORS.green};
    border: 0;
    box-shadow: 0 0.25em ${COLORS.greenDarker};
  }
`;

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  styleType?: 'primary' | 'secondary';
}

export function Button(props: ButtonProps) {
  return (
    <ButtonStyled
      {...props}
      className={`transition-all ${props.className} ${
        props.styleType || 'primary'
      }`}
    >
      {props.children}
    </ButtonStyled>
  );
}
