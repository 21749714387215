import { Storage, PopulatedLocation } from '@api/models';
import { SortStorageEnum } from '../../enums';
import {
  sortNumDesc,
  sortNumStrAsc,
  sortNumStrDesc,
  toStorageSort
} from '../../utils';
import {
  LocationsModel,
  SearchModel,
  StorageEnrichedModel,
  StoragesStoreModel,
  UserUiModel
} from '../../models';

const sortStorages =
  (sortBy: SortStorageEnum | null) =>
  (storages: Storage[]): Storage[] => {
    switch (sortBy) {
      case SortStorageEnum.Decrease:
        return storages.sort(toStorageSort('number', sortNumStrDesc));

      case SortStorageEnum.Increase:
        return storages.sort(toStorageSort('number', sortNumStrAsc));

      case SortStorageEnum.AvailableToUsed:
        return storages.sort((a, _b) => (!a.startDate ? -1 : 1));

      case SortStorageEnum.UsedToAvailable:
        return storages.sort((a, _b) => (a.startDate ? -1 : 1));

      case SortStorageEnum.Smallest:
        return storages.sort((a, b) => sortNumDesc(b.size || 0, a.size || 0));

      case SortStorageEnum.Largest:
        return storages.sort((a, b) => sortNumDesc(a.size || 0, b.size || 0));

      case SortStorageEnum.StoredOnCustomer:
        return storages.sort((a, _b) => (a.storedOnCustomer ? -1 : 1));

      default:
        return storages;
    }
  };

const searchStorages =
  ({ field, term }: SearchModel) =>
  (storages: Storage[]): Storage[] => {
    if (field && term) {
      return storages.filter(
        (storage) =>
          storage[field] &&
          (storage[field] as string)
            .toString()
            .toLowerCase()
            .includes(term.toLowerCase())
      );
    }
    return storages;
  };

export const getLocationsList = (
  locations: LocationsModel,
  search: SearchModel,
  sort: SortStorageEnum | null
): PopulatedLocation[] => {
  return Object.keys(locations).map((locationId) => ({
    ...locations[locationId],
    storages: sortStorages(sort)(
      searchStorages(search)(locations[locationId].storages)
    )
  }));
};

export const getLocationsListFromStore = ({
  locations,
  sort,
  search
}: StoragesStoreModel): PopulatedLocation[] => {
  // console.log('Get locations!', locations, sort, search);
  return getLocationsList(locations, search, sort);
};

export const getEnrichedStorage =
  ({ locations }: StoragesStoreModel) =>
  (storageId: string, locationId: string): StorageEnrichedModel => {
    const location = locations[locationId];
    if (!location) {
      return {} as any;
    }
    const storage = location.storages.find(
      (storage) => storage._id === storageId
    ) as Storage;
    return {
      ...storage,
      locationAddress: location.address as string
    };
  };

export const getUsers = ({ users }: StoragesStoreModel): UserUiModel[] => {
  return Object.keys(users).map((userId) => users[userId]);
};
