import { useEffect, useRef, useCallback, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Storage } from '@api/models';
import { STORAGE_PATH } from '../../constants';
import {
  storageActions,
  StoragesDispatchContext,
  StoragesStateContext
} from '../../state/storages';
import { LocationsHttpService } from '../../services';
import { StoragesList } from './StoragesList';

const UNHIGHLIGHT_TIMEOUT = 5000;

interface StoragesProp {
  scrollTo: (position: number) => void;
}

export function Storages(props: StoragesProp) {
  const state = useContext(StoragesStateContext);
  const dispatch = useContext(StoragesDispatchContext);
  const history = useHistory();
  const { path } = useRouteMatch();
  const historyListener = useRef<any>(null);
  const unhighlightTimeout = useRef<NodeJS.Timeout | null>(null);

  const { locations, search, sort, scrollToStorage } = state;
  const { elementPosition } = scrollToStorage;

  // Fetch storages once on init
  useEffect(() => {
    async function fetchStorages() {
      const locations = await LocationsHttpService.getLocations();
      if (locations.data) {
        dispatch(storageActions.setLocations(locations.data));
      }
    }
    fetchStorages();
  }, [dispatch]);

  // Set timeout to remove storage highlight
  const setUnhighlightTimeout = useCallback(() => {
    if (unhighlightTimeout.current) {
      clearTimeout(unhighlightTimeout.current);
    }

    unhighlightTimeout.current = setTimeout(() => {
      dispatch(
        storageActions.setScrollTo({
          locationId: null,
          storageId: null,
          elementPosition: null
        })
      );
    }, UNHIGHLIGHT_TIMEOUT);
  }, [dispatch]);

  const scrollToElement = useCallback(() => {
    if (typeof elementPosition !== 'number') {
      return;
    }

    // Scroll to current highlighted storage element position
    props.scrollTo(elementPosition);

    setUnhighlightTimeout();
  }, [setUnhighlightTimeout, elementPosition, props]);

  // Scroll to element only when user goes back to dashboard
  useEffect(() => {
    if (historyListener.current) {
      historyListener.current();
    }

    historyListener.current = history.listen((location, action) => {
      const isPathname =
        location.pathname === '/dashboard' ||
        location.pathname === '/dashboard/storages';
      if (isPathname && (action === 'POP' || action === 'PUSH')) {
        scrollToElement();
      }
    });
  }, [history, scrollToElement]);

  const onStorageClick = useCallback(
    (storage: Storage): void => {
      history.push(
        `${path}/${STORAGE_PATH}/edit/${storage._id}/${storage.locationId}`
      );
    },
    [history, path]
  );

  return (
    <StoragesList
      locations={locations}
      search={search}
      sort={sort}
      scrollToStorage={scrollToStorage}
      onStorageClick={onStorageClick}
    />
  );
}
