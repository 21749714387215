import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';

import { MAIN_PATH } from './constants';
import { Dashboard, Login } from './components/pages';
import { StoragesProvider } from './state/storages';
import {
  registerRequestInterceptor,
  registerResponseInterceptor
} from './interceptors';

registerRequestInterceptor();
registerResponseInterceptor();

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route path={`/${MAIN_PATH}`}>
          <StoragesProvider>
            <Dashboard />
          </StoragesProvider>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
