import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import { PopulatedLocation } from '@api/models';

import { REGION_ITEMS, StorageFormFields } from '../../constants';
import { NewStorageData, TypeWithValues } from '../../models';
import { hasErrors } from '../../utils';
import {
  Dropdown,
  Form,
  Input,
  RegionItem,
  Select,
  SelectItem
} from '../shared';

type StorageErrors = TypeWithValues<NewStorageData, boolean>;

interface ComponentProps {
  locations: PopulatedLocation[];
  onSubmit: (formData: NewStorageData) => void;
  errorMsg?: string;
}

const AddStorageFormStyles = styled.div`
  height: 100%;

  .col-number {
    width: 40%;
    margin-right: 1rem;
  }

  .col-size {
    flex-grow: 1;
  }
`;

export function AddStorageForm(props: ComponentProps) {
  const { locations } = props;
  const locationItems = useRef<SelectItem[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [formErrors, setFormErrors] = useState<StorageErrors>({});
  const [formState, setFormState] = useState<NewStorageData>({
    locationId: locations.length ? locations[0]._id : ''
  } as NewStorageData);

  if (locations.length !== locationItems.current.length) {
    locationItems.current = locations.map((location) => ({
      label: location.address,
      value: location._id as string
    }));
  }

  useEffect(() => {
    setErrorMsg(props.errorMsg || '');
  }, [props.errorMsg]);

  const handleChange = (e: any) => {
    const { target } = e;
    setFormState({
      ...formState,
      [target.name]: target.value
    });
    if (target.value && formErrors[target.name as keyof StorageErrors]) {
      setFormErrors({
        ...formErrors,
        [target.name]: false
      });
    }
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const errors = {
      number: !formState.number,
      width: !formState.width,
      depth: !formState.depth,
      position: !formState.position
    };
    setFormErrors(errors);
    setErrorMsg('');

    if (!hasErrors(errors)) {
      props.onSubmit(formState);
    }
  };

  return (
    <AddStorageFormStyles>
      <Form
        submitLabel="register"
        onSubmit={onSubmit}
        hasError={hasErrors(formErrors) || !!errorMsg}
        errorMessage={errorMsg}
      >
        <div className="form-col col-number">
          <label className="form-label">Number</label>
          <Input
            name={StorageFormFields.number}
            placeholder="Add number"
            value={formState.number || ''}
            hasError={formErrors.number}
            onChange={handleChange}
            autoComplete="off"
          />
        </div>
        <div className="form-col col-size">
          <label className="form-label">Size</label>
          <div className="form-col__fields-wrap">
            <Input
              name={StorageFormFields.width}
              placeholder="Width"
              value={formState.width || ''}
              hasError={formErrors.width}
              onChange={handleChange}
            />
            <span className="form-col__text">x</span>
            <Input
              name={StorageFormFields.depth}
              placeholder="Depth"
              value={formState.depth || ''}
              hasError={formErrors.depth}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-col is-full">
          <label className="form-label">Choose a position</label>
          <Dropdown
            name={StorageFormFields.position}
            placeholder="Choose a color for the region"
            items={REGION_ITEMS}
            value={formState.position}
            hasError={formErrors.position}
            onChange={handleChange}
            itemComponent={RegionItem}
            valueComponent={RegionItem}
          ></Dropdown>
        </div>
        <div className="form-col is-full">
          <label className="form-label">Location</label>
          <Select
            name={StorageFormFields.locationId}
            items={locationItems.current}
            value={formState.locationId}
            onChange={handleChange}
          />
        </div>
      </Form>
    </AddStorageFormStyles>
  );
}
