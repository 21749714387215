import { useContext, useRef, useState } from 'react';

import { MAIN_PATH } from '../../constants';
import { NewStorageData } from '../../models';
import { StoragesHttpService } from '../../services';
import {
  getLocationsListFromStore,
  storageActions,
  StoragesDispatchContext,
  StoragesStateContext
} from '../../state/storages';
import { Takeover } from '../elements';
import { AddStorageForm } from '../forms';
import { Message } from '../shared';

export function AddStorageTakeover() {
  const takeover = useRef(null);
  const dispatch = useContext(StoragesDispatchContext);
  const locations = getLocationsListFromStore(useContext(StoragesStateContext));
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [confirmationMsg, setConfirmationMsg] = useState<string>('');
  const exitTo = `/${MAIN_PATH}`;
  let saving = false;

  const closeTakeover = () => (takeover?.current as any).close();

  const onSubmit = async (formData: NewStorageData) => {
    if (saving) {
      return;
    }
    saving = true;
    setErrorMsg('');

    try {
      const result = await StoragesHttpService.add(formData);
      if (result.status === 201) {
        setConfirmationMsg('Register Completed with Success');
        dispatch(storageActions.addStorage(result.data));
        dispatch(
          storageActions.setScrollTo({
            locationId: result.data.locationId,
            storageId: result.data._id
          })
        );
      } else {
        throw result;
      }
    } catch (error: any) {
      setErrorMsg(error.response.data.error);
    } finally {
      saving = false;
    }
  };

  return (
    <Takeover
      fullScreen
      title="Register Storage"
      subtitle="Add the minimum information about each storage"
      ref={takeover}
      exitTo={exitTo}
    >
      {confirmationMsg ? (
        <Message
          title={confirmationMsg}
          onConfirm={closeTakeover}
          actionLabel="go to dashboard"
          successImg
        />
      ) : (
        <AddStorageForm
          locations={locations}
          onSubmit={onSubmit}
          errorMsg={errorMsg}
        />
      )}
    </Takeover>
  );
}
