import styled from 'styled-components';

import { COLORS } from '../../styles';
import { ArrowIcon } from '../icons';

const MenuLinkStyles = styled.button`
  align-items: baseline;
  background: none;
  border: 0;
  border-bottom: 1px solid ${COLORS.greyLight};
  color: ${COLORS.black};
  display: flex;
  line-height: 0.9;
  font-size: 1.75rem;
  padding: 0.6rem 0 0.85rem;
  width: 100%;
  transition: color 0.3s ease;

  .menu-link__icon {
    transition: color 0.3s ease;
    fill: ${COLORS.greyLight};
    margin-left: auto;
  }

  &:hover {
    cursor: pointer;
    color: ${COLORS.greyMedium};

    .menu-link__icon {
      fill: ${COLORS.greyMedium};
    }
  }

  &.is-selected {
    color: ${COLORS.blue};

    .menu-link__icon {
      fill: ${COLORS.blue};
    }
  }
`;

export interface MenuLinkProps {
  label: string;
  onClick: () => void;
  selected?: boolean;
}

export function MenuLink(props: MenuLinkProps) {
  const className = props.selected ? 'is-selected' : '';
  return (
    <MenuLinkStyles onClick={props.onClick} className={className}>
      {props.label}
      <ArrowIcon className="menu-link__icon"></ArrowIcon>
    </MenuLinkStyles>
  );
}
