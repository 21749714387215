import { useEffect, useRef, useContext, useCallback } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { STORAGE_PATH } from '../../constants';
import { COLORS, MEDIAS } from '../../styles';
import { SearchFields, UserMenuActionsEnum } from '../../enums';
import { storageActions, StoragesDispatchContext } from '../../state/storages';
import { PageHeader, PageHeaderActions } from '../shared/PageHeader';
import {
  AddLocationTakeover,
  AddStorageTakeover,
  AddUserTakeover,
  EditStorageTakeover,
  SearchTakeover,
  SortTakeover,
  UserTakeover
} from '../takeovers';
import { H2 } from '../shared';
import { Storages } from '../sections/Storages';

const sidebarWidth = '130px';

const PageContainer = styled.div`
  height: 100vh;
  overflow: hidden;

  .header,
  .content {
    position: fixed;
    left: 0;
    width: 100%;
  }

  .header {
    top: 0;
    height: 70px;
    z-index: 1;
  }

  .content {
    top: 70px;
    height: calc(100% - 70px);
    overflow-x: auto;
  }

  .sidebar {
    display: none;
  }

  @media ${MEDIAS.large} {
    .header,
    .content {
      left: ${sidebarWidth};
      width: calc(100% - ${sidebarWidth});
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .sidebar {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: ${sidebarWidth};
      background: ${COLORS.blue};
      padding: 1rem 1rem 2rem;

      &:hover {
        cursor: pointer;
      }

      &__logo {
        width: 100%;
      }

      &__title {
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        margin-top: auto;
        font-size: 2rem;
        margin-left: -0.5rem;
        transition: 0.2s opacity ease-in-out;
      }
    }

    .sidebar:hover .sidebar__title {
      opacity: 0.5;
    }
  }
`;

export function Dashboard() {
  const dispatch = useContext(StoragesDispatchContext);
  const history = useHistory();
  const { path } = useRouteMatch();
  const contentRef = useRef<HTMLDivElement>(null);

  // console.log('Hey Dashboard', path);

  // Scroll to top when it initializes to fix a Safari bug after login
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList.add('no-scroll');
  }, []);

  const onHeaderAction = (action: PageHeaderActions) => {
    history.push(`${path}/${action}`);
  };

  const onSidebarClick = () => {
    dispatch(storageActions.setSort(null));
    dispatch(
      storageActions.setSearch({ field: SearchFields.Client, term: '' })
    );
  };

  const onScrollTo = useCallback((position: number): void => {
    contentRef.current?.scroll({
      top: position - 30,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div>
      <PageContainer>
        <div className="header">
          <PageHeader title="Dashboard" onAction={onHeaderAction}></PageHeader>
        </div>
        <div className="content" ref={contentRef}>
          <Storages scrollTo={onScrollTo} />
        </div>
        <div className="sidebar" onClick={onSidebarClick}>
          <img
            className="sidebar__logo"
            src={'/svg/logo.svg'}
            alt="Lunardi Logo"
          />
          <H2 className="sidebar__title" color="white">
            Storage System
          </H2>
        </div>
      </PageContainer>

      <Route path={`${path}/${UserMenuActionsEnum.createStorage}`}>
        <AddStorageTakeover />
      </Route>
      <Route path={`${path}/${STORAGE_PATH}/edit/:storageId/:locationId`}>
        <EditStorageTakeover />
      </Route>
      <Route path={`${path}/${UserMenuActionsEnum.createLocation}`}>
        <AddLocationTakeover />
      </Route>
      <Route path={`${path}/${UserMenuActionsEnum.createUser}`}>
        <AddUserTakeover />
      </Route>
      <Route path={`${path}/${PageHeaderActions.Search}`}>
        <SearchTakeover />
      </Route>
      <Route path={`${path}/${PageHeaderActions.Sort}`}>
        <SortTakeover />
      </Route>
      <Route path={`${path}/${PageHeaderActions.User}`}>
        <UserTakeover />
      </Route>
    </div>
  );
}
