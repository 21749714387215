import styled from 'styled-components';

import { COLORS } from '../../styles';

interface InputProps {
  hasError?: boolean;
}

export const Input = styled.input<InputProps>`
  appearance: none;
  background-color: ${(props) =>
    props.disabled ? COLORS.greyLighter : '#fff'};
  border-radius: 10px;
  border: 1px solid
    ${(props) => (props.hasError ? COLORS.red : COLORS.greyLight)};
  box-shadow: 0 0.25em ${COLORS.greyLight};
  color: ${COLORS.black};
  font-size: 1.15rem;
  padding: 0.55rem 0.75rem;

  &[type='date'] {
    min-height: 56px;
    width: 100%;
  }

  ::placeholder {
    color: ${COLORS.grey};
  }

  :disabled {
    color: ${COLORS.grey};
  }

  :focus {
    border-color: ${COLORS.greyMedium};
    outline: 0;
  }

  :-webkit-autofill {
    &,
    &:hover,
    &:focus,
    &:active {
      /* The first shadow is to avoid getting the input with blue background when being autocompleted */
      box-shadow: 0 0 0px 1000px #ffffff inset, 0 0.25em ${COLORS.greyLight};
    }
  }

  /* Hide calendar icon of date input on desktop */
  /* &[type='date']::-webkit-inner-spin-button,
  &[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    appearance: none;
  } */
`;
