import styled from 'styled-components';

import { COLORS } from '../../styles';
import { CheckIcon } from '../icons';

type RadioGroupSizes = 'small';

const sizes: { [k in RadioGroupSizes]: string } = {
  small: '2.75rem'
};

interface RadioGroupStylesProps {
  size?: RadioGroupSizes;
}

const RadioGroupStyles = styled.div<RadioGroupStylesProps>`
  display: flex;
  border: 1px solid ${COLORS.greyLight};
  border-radius: 10px;

  .radio-item {
    flex-grow: 1;
    height: 3rem;

    &__label {
      align-items: center;
      display: flex;
      flex-grow: 1;
      height: 100%;
      padding: 0;

      &:hover {
        cursor: pointer;
      }
    }

    &__box {
      align-items: center;
      border-radius: 10px;
      display: flex;
      height: 100%;
      justify-content: center;
      margin-right: 1rem;
      width: ${(props) => (props.size ? sizes[props.size] : '3.75rem')};
    }

    &__icon {
      display: none;

      &.is-selected {
        display: block;
      }
    }

    & > input {
      display: none;
    }
  }
`;

export interface RadioItem {
  name: string;
  value: string;
  label: string;
  color?: string;
}

export interface RadioGroupProps extends RadioGroupStylesProps {
  name: string;
  value: string;
  items: RadioItem[];
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export function RadioGroup(props: RadioGroupProps) {
  return (
    <RadioGroupStyles size={props.size}>
      {props.items.map((item) => (
        <div
          className={`radio-item ${
            item.value === props.value ? 'is-selected' : ''
          }`}
          key={item.name}
        >
          <input
            type="radio"
            id={item.name}
            name={props.name}
            value={item.value}
            checked={item.value === props.value}
            onChange={props.onChange}
          />
          <label className="radio-item__label" htmlFor={item.name}>
            <span
              className="radio-item__box"
              style={{ backgroundColor: item.color || COLORS.blue }}
            >
              <CheckIcon
                className={`radio-item__icon ${
                  item.value === props.value ? 'is-selected' : ''
                }`}
              />
            </span>
            {item.label}
          </label>
        </div>
      ))}
    </RadioGroupStyles>
  );
}
