import styled from 'styled-components';

import { COLORS } from '../../styles';

const StatusLabelStyles = styled.span`
  background: ${COLORS.green};
  border-radius: 10px;
  color: white;
  font-size: 0.85rem;
  min-width: 6.75rem;
  padding: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &.used {
    background: ${COLORS.red};
  }
`;

export interface StatusLabelProps {
  isUsed: boolean;
}

export function StatusLabel(props: StatusLabelProps) {
  return (
    <StatusLabelStyles className={props.isUsed ? 'used' : ''}>
      {props.isUsed ? 'used' : 'available'}
    </StatusLabelStyles>
  );
}
