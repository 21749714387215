import styled from 'styled-components';

import { COLORS } from '../../styles';
import { PropsWithChildren } from 'react';

const MenuSectionStyles = styled.div`
  .title {
    color: ${COLORS.greyMedium};
    font-size: 0.9rem;
    margin: 0;
  }
`;

export interface MenuSectionProps {
  title: string;
  className?: string;
}

export function MenuSection(props: PropsWithChildren<MenuSectionProps>) {
  return (
    <MenuSectionStyles className={props.className}>
      <p className="title">{props.title}</p>
      {props.children}
    </MenuSectionStyles>
  );
}
