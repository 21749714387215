import { COLORS } from '../../styles';
import { IconProps } from '../../models';

export function CloseIcon(props: IconProps) {
  const color = COLORS[props.color || 'blue'];
  return (
    <svg
      className={props.className}
      width={props.width || 17}
      height={props.height || 15}
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5801 0.719514C15.0695 0.2588 14.3766 -6.10352e-05 13.6541 -6.10352e-05C12.9317 -6.10352e-05 12.2388 0.2588 11.7282 0.719514L8.20589 3.89435L4.68359 0.719514C3.6147 -0.211088 1.91565 -0.197779 0.864864 0.749427C-0.185923 1.69663 -0.200687 3.2282 0.83168 4.19173L4.3537 7.36681L0.83168 10.5419C0.123526 11.1584 -0.16048 12.0714 0.0888191 12.93C0.338119 13.7885 1.08193 14.459 2.03436 14.6837C2.9868 14.9085 3.99964 14.6525 4.68359 14.0141L8.20589 10.8393L11.7282 14.0141C12.7971 14.9447 14.4961 14.9314 15.5469 13.9842C16.5977 13.037 16.6125 11.5054 15.5801 10.5419L12.0581 7.36681L15.5801 4.19173C16.0912 3.73143 16.3784 3.10688 16.3784 2.45562C16.3784 1.80436 16.0912 1.17981 15.5801 0.719514Z"
      />
    </svg>
  );
}
