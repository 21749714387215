import React, { useMemo, useReducer } from 'react';
import { StoragesDispatchContext, StoragesStateContext } from './contexts';
import { storagesReducer, initialState } from './reducer';

export const StoragesProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(storagesReducer, initialState);
  const store = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <StoragesStateContext.Provider value={store.state}>
      <StoragesDispatchContext.Provider value={store.dispatch}>
        {children}
      </StoragesDispatchContext.Provider>
    </StoragesStateContext.Provider>
  );
};
