import React from 'react';

import { StorageActionsUnion } from './actions';
import { initialState } from './reducer';

export const StoragesStateContext = React.createContext(initialState);

export const StoragesDispatchContext = React.createContext(
  (() => {}) as React.Dispatch<StorageActionsUnion>
);
