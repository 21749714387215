import axios from 'axios';

import { getUserToken } from '../utils';

let registered: boolean;

export const registerRequestInterceptor = () => {
  if (registered) {
    return;
  }

  registered = true;
  axios.interceptors.request.use(function (config) {
    const token = getUserToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};
