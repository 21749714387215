// TODO: Duplicating this enum here because for now the client doesnt want to import it
// from @api/enums .. need to investigate how to fix that
// the same for any @client import to avoid many ../../ is still not working
export enum StoragePositions {
  Front = 'front',
  Back = 'back',
  Region1 = 'region-1',
  Region2 = 'region-2',
  Region3 = 'region-3',
  Region4 = 'region-4',
  Region5 = 'region-5',
  Region6 = 'region-6',
  Region7 = 'region-7'
}
