import { useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Storage } from '@api/models';
import { StorageEnrichedModel } from '../../models';
import { StoragesHttpService } from '../../services';
import {
  getEnrichedStorage,
  storageActions,
  StoragesDispatchContext,
  StoragesStateContext
} from '../../state/storages';
import { Takeover } from '../elements';
import { EditStorageForm } from '../forms';
import { Message } from '../shared';

interface EditStorageParams {
  storageId: string;
  locationId: string;
}
export function EditStorageTakeover() {
  const takeover = useRef(null);
  const params = useParams<EditStorageParams>();
  const [confirmationMsg, setConfirmationMsg] = useState<{
    __html: string;
  }>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [storageToDelete, setStorageToDelete] =
    useState<StorageEnrichedModel | null>(null);

  const dispatch = useContext(StoragesDispatchContext);
  const state = useContext(StoragesStateContext);
  const storage = getEnrichedStorage(state)(
    params.storageId,
    params.locationId
  );
  let saving = false;

  const closeTakeover = () => (takeover?.current as any).close();

  const onConfirm = () => {
    closeTakeover();
  };

  const onCancel = () => {
    setStorageToDelete(null);
    setErrorMsg('');
  };

  const onEdit = () => {
    setIsEditing(!isEditing);
  };

  const onSubmit = async (formData: Storage) => {
    if (saving) {
      return;
    }
    saving = true;

    try {
      let apiCall: any;
      let storageData: Storage;
      let successMsg: string;
      const startUsage = !storage.startDate && !formData.endDate;
      const endUsage = !!formData.startDate && !!formData.endDate;

      if (isEditing) {
        apiCall = await StoragesHttpService.update(formData);
        storageData = apiCall.data;
        successMsg = 'The storage was edited successfully!';
      } else if (startUsage) {
        apiCall = await StoragesHttpService.startUsage(formData);
        storageData = apiCall.data;
        successMsg = 'The storage is now in use!';
      } else if (endUsage) {
        apiCall = await StoragesHttpService.setEndDate({
          _id: storage._id,
          endDate: formData.endDate
        });
        storageData = apiCall.data.storage;
        successMsg =
          'Final date was set for storage. <br/> It is now available!';
      } else {
        apiCall = await StoragesHttpService.updateSpecialFields({
          _id: storage._id,
          storedOnCustomer: formData.storedOnCustomer,
          notes: formData.notes
        });
        storageData = apiCall.data;
        successMsg = 'Storage usage information updated!';
      }

      if (apiCall.status === 200) {
        onSubmitSuccess(storageData, successMsg);
      } else {
        throw apiCall;
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      saving = false;
    }
  };

  const onSubmitSuccess = (storageData: Storage, message: string) => {
    setConfirmationMessage(message);
    dispatch(storageActions.updateStorage(storageData));
    setScrollToStorage(storageData);
  };

  const onDeleteConfirm = async () => {
    if (saving || !storageToDelete) {
      return;
    }
    saving = true;

    try {
      const result = await StoragesHttpService.delete(
        storageToDelete._id as string
      );
      if (result.status === 200) {
        setConfirmationMessage('The storage was deleted from the location.');
        dispatch(storageActions.deleteStorage(storageToDelete));
      } else {
        throw result;
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      saving = false;
    }
  };

  const handleApiError = (errorObj: any) => {
    const { error, message } = errorObj.response.data;
    setErrorMsg(message || error);
  };

  const setConfirmationMessage = (msg: string): void => {
    setConfirmationMsg({ __html: msg });
  };

  const setScrollToStorage = ({ locationId, _id }: Storage): void => {
    dispatch(
      storageActions.setScrollTo({
        locationId: locationId,
        storageId: _id,
        elementPosition: null
      })
    );
  };

  return (
    <Takeover
      fullScreen
      title={`${isEditing ? 'Editing ' : ''} Storage Info`}
      titleColor={isEditing ? 'orange' : 'blue'}
      subtitle={!isEditing ? storage.locationAddress : ''}
      fixedContent={!!storageToDelete}
      ref={takeover}
    >
      {confirmationMsg ? (
        <Message
          title={`Storage successfully ${
            storageToDelete ? 'deleted' : 'edited'
          }!`}
          onConfirm={onConfirm}
          actionLabel="go to dashboard"
        >
          <div dangerouslySetInnerHTML={confirmationMsg}></div>
        </Message>
      ) : (
        <>
          <EditStorageForm
            storage={storage}
            isEditing={isEditing}
            onSubmit={onSubmit}
            onEdit={onEdit}
            onDelete={setStorageToDelete}
            errorMsg={errorMsg}
          />
          {storageToDelete && (
            <Message
              title="Are you sure?"
              onConfirm={onDeleteConfirm}
              onCancel={onCancel}
              errorMsg={errorMsg}
              actionLabel="delete"
              deleteImg
              absolute
            >
              The storage
              <strong> {storageToDelete?.number} </strong>
              will be permanently deleted. <br />
              <br />
              However, you will be able to create it again in this location or
              another one if the storage number there is not in use.
            </Message>
          )}
        </>
      )}
    </Takeover>
  );
}
