import { COLORS } from '../../styles';
import { IconProps } from '../../models';

export function FilterIcon(props: IconProps) {
  const color = COLORS[props.color || 'blue'];
  return (
    <svg
      className={props.className}
      width={props.width || 18}
      height={props.height || 18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75001 18H11.25C11.8713 18 12.375 17.4963 12.375 16.875V10.448L17.8287 1.72046C18.0455 1.37383 18.0571 0.936796 17.8589 0.579186C17.6606 0.221576 17.2839 -0.000215735 16.875 1.57469e-07H1.12501C0.716119 -0.000200423 0.33936 0.221623 0.141155 0.579262C-0.0570504 0.936902 -0.0454457 1.37396 0.171459 1.72058L5.62501 10.448V16.875C5.62501 17.4963 6.12869 18 6.75001 18ZM10.125 15.75H7.87501V10.125C7.87516 9.91433 7.81576 9.70791 7.70367 9.52954L3.15541 2.25H14.8446L10.2963 9.52954C10.1843 9.70791 10.1249 9.91433 10.125 10.125V15.75Z"
      />
    </svg>
  );
}
