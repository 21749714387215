import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { MAIN_PATH } from '../../constants';
import { UserMenuActionsEnum } from '../../enums';
import { getUserData, unlogUser } from '../../utils';
import { Takeover } from '../elements';
import { MenuLink, TakeoverHeader } from '../shared';
import { isUserAdmin } from '../../utils/users.utils';

const UserTakeoverStyles = styled.div`
  .section:not(:first-child) {
    margin-top: 0.85rem;
  }

  .user-info {
    padding: 2rem 0;

    .subtitle {
      text-transform: capitalize;
    }
  }
`;

export function UserTakeover() {
  const takeover = useRef(null);
  const history = useHistory();
  const [exitTo, setExitTo] = useState<string>();
  const loggedUser = getUserData();
  const isAdmin = isUserAdmin(loggedUser);

  const onLinkClick = (action: UserMenuActionsEnum) => () => {
    setExitTo(`/${MAIN_PATH}/${action}`);
    (takeover?.current as any).close();
  };

  const onLogout = () => {
    unlogUser();
    history.push('/');
  };

  return (
    <Takeover title="Profile" ref={takeover} exitTo={exitTo}>
      <UserTakeoverStyles>
        <TakeoverHeader
          className="user-info no-shadow"
          title={loggedUser?.name as string}
          subtitle={loggedUser?.role}
        ></TakeoverHeader>
        {isAdmin && (
          <>
            <MenuLink
              label="Register Storage"
              onClick={onLinkClick(UserMenuActionsEnum.createStorage)}
            ></MenuLink>
            <MenuLink
              label="Register Location"
              onClick={onLinkClick(UserMenuActionsEnum.createLocation)}
            ></MenuLink>
            <MenuLink
              label="Create User"
              onClick={onLinkClick(UserMenuActionsEnum.createUser)}
            ></MenuLink>
          </>
        )}
        <MenuLink label="Sign out" onClick={onLogout}></MenuLink>
      </UserTakeoverStyles>
    </Takeover>
  );
}
