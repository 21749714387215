import { useEffect, useState } from 'react';

import { Storage, StorageProps } from '@api/models';
import { REGION_ITEMS, StorageFormFields } from '../../constants';
import { StorageEnrichedModel, TypeWithValues } from '../../models';
import {
  dateSubsStr,
  hasErrors,
  getIsToggleUpdate,
  getIsUserAdmin,
  getIsNotesUpdate
} from '../../utils';
import { useGetLocationsOptions } from '../../state/storages';
import { DeleteIcon, EditIcon } from '../icons';
import {
  Dropdown,
  Form,
  Input,
  RegionItem,
  StatusLabel,
  Textarea,
  Toggle
} from '../shared';

type StorageErrors = TypeWithValues<Storage, boolean>;

type StorageFormState = {
  [K in Exclude<StorageProps, 'size'>]: Storage[K];
};

interface ComponentProps {
  storage: StorageEnrichedModel;
  isEditing: boolean;
  onSubmit: (formData: Storage) => void;
  onDelete: (storage: StorageEnrichedModel) => void;
  onEdit: (storage: StorageEnrichedModel) => void;
  errorMsg?: string;
}

export function EditStorageForm(props: ComponentProps) {
  const { storage } = props;
  const [formErrors, setFormErrors] = useState<StorageErrors>({});
  const [formState, setFormState] = useState<StorageFormState>({} as any);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const isAdmin = getIsUserAdmin();
  const locationsOptions = useGetLocationsOptions();
  const buttonLabel = props.isEditing ? 'save' : 'update';

  if (Object.keys(storage).length && !Object.keys(formState).length) {
    setFormState({
      _id: storage._id,
      position: storage.position,
      locationId: storage.locationId,
      number: storage.number,
      depth: storage.depth,
      width: storage.width,
      clientName: storage.clientName,
      projectNumber: storage.projectNumber,
      startDate: storage.startDate ? dateSubsStr(storage.startDate) : undefined,
      endDate: storage.endDate ? dateSubsStr(storage.endDate) : undefined,
      storedOnCustomer: storage?.storedOnCustomer,
      notes: storage?.notes
    });
  }

  useEffect(() => {
    setInProgress(false);
  }, [props.errorMsg]);

  const handleChange = ({ target: { name, type, value, checked } }: any) => {
    const currValue = type === 'checkbox' ? checked : value;
    setFormState({
      ...formState,
      [name]: currValue
    });
    if (value && formErrors[name as keyof StorageErrors]) {
      setFormErrors({
        ...formErrors,
        [name]: false
      });
    }
  };

  const getFormErrors = (): StorageErrors => {
    let errors: StorageErrors;
    const isSavingClient = !storage.clientName;
    if (isSavingClient) {
      errors = {
        clientName: !formState.clientName,
        projectNumber: !formState.projectNumber,
        startDate: !formState.startDate
      };
    } else {
      errors = {
        endDate: !formState.endDate
      };
    }

    return errors;
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const isToggleUpdate = getIsToggleUpdate(props.storage, formState);
    const isNotesUpdate = getIsNotesUpdate(props.storage, formState);
    const notSpecialFieldsUpdate = !isToggleUpdate && !isNotesUpdate;

    // Run errors validation only for non Editing mode and non update of toggle or notes
    if (!props.isEditing && notSpecialFieldsUpdate) {
      const errors = getFormErrors();
      if (hasErrors(errors)) {
        setFormErrors(errors);
        return;
      }
    }

    setInProgress(true);
    props.onSubmit({ ...formState, _id: storage._id });
  };

  return (
    <Form
      submitLabel={buttonLabel}
      isLoading={inProgress}
      onSubmit={onSubmit}
      hasError={hasErrors(formErrors) || !!props.errorMsg}
      errorMessage={props.errorMsg}
    >
      <div className="form-col">
        <StatusLabel isUsed={!!storage.startDate} />
      </div>
      <div className="form-col content-right">
        {isAdmin && (
          <>
            <EditIcon
              className="form-icon"
              color={props.isEditing ? 'orange' : 'blue'}
              onClick={() => props.onEdit(storage)}
            />
            <DeleteIcon
              className="form-icon"
              onClick={() => props.onDelete(storage)}
            />
          </>
        )}
      </div>
      <div className="form-col">
        <label className="form-label">Number</label>
        <Input
          disabled={!props.isEditing}
          name={StorageFormFields.number}
          value={formState.number || ''}
          onChange={handleChange}
        />
      </div>
      <div className="form-col col-size">
        <label className="form-label">Size (width x depth)</label>
        <div className="form-col__fields-wrap">
          <Input
            disabled={!props.isEditing}
            name={StorageFormFields.width}
            placeholder="Width"
            value={formState.width || ''}
            hasError={formErrors.width}
            onChange={handleChange}
          />
          <span className="form-col__text">x</span>
          <Input
            disabled={!props.isEditing}
            name={StorageFormFields.depth}
            placeholder="Depth"
            value={formState.depth || ''}
            hasError={formErrors.depth}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-col">
        <label className="form-label">Client Name</label>
        <Input
          disabled={!!storage.startDate && !props.isEditing}
          name={StorageFormFields.clientName}
          value={formState.clientName || ''}
          hasError={!formState.clientName && formErrors.clientName}
          onChange={handleChange}
          placeholder="Add name"
          autoComplete="off"
        />
      </div>
      <div className="form-col">
        <label className="form-label">Project Number</label>
        <Input
          disabled={!!storage.startDate && !props.isEditing}
          name={StorageFormFields.projectNumber}
          value={formState.projectNumber || ''}
          hasError={!formState.projectNumber && formErrors.projectNumber}
          onChange={handleChange}
          placeholder="Add number"
          autoComplete="off"
        />
      </div>
      {props.isEditing && (
        <>
          <div className="form-col is-full">
            <label className="form-label">Choose a position</label>
            <Dropdown
              name={StorageFormFields.position}
              placeholder="Choose a color for the region"
              items={REGION_ITEMS}
              value={formState.position}
              onChange={handleChange}
              itemComponent={RegionItem}
              valueComponent={RegionItem}
            />
          </div>
          <div className="form-col is-full">
            <label className="form-label">Address</label>
            <Dropdown
              name={StorageFormFields.locationId}
              placeholder="Pick an address"
              items={locationsOptions}
              value={formState.locationId}
              onChange={handleChange}
            />
          </div>
        </>
      )}
      <div className="form-col">
        <label className="form-label">Start Date</label>
        <Input
          name={StorageFormFields.startDate}
          type="date"
          disabled={!!storage.startDate && !props.isEditing}
          value={formState.startDate || ''}
          hasError={!formState.startDate && formErrors.startDate}
          onChange={handleChange}
          placeholder="--"
          autoComplete="off"
        />
      </div>
      <div className="form-col">
        <label className="form-label">Final Date</label>
        <Input
          name={StorageFormFields.endDate}
          type="date"
          disabled={!storage.startDate}
          value={formState.endDate || ''}
          hasError={
            !!storage.startDate && !formState.endDate && formErrors.endDate
          }
          onChange={handleChange}
          placeholder="--"
          autoComplete="off"
        />
      </div>
      {!props.isEditing && storage.startDate && (
        <>
          <div className="form-col is-full">
            <Toggle
              name={StorageFormFields.storedOnCustomer}
              label="Enable option if Portable Storage goes to the customer."
              toggled={formState.storedOnCustomer}
              onChange={handleChange}
            />
          </div>
          <div className="form-col is-full">
            <label className="form-label">Notes</label>
            <Textarea
              name={StorageFormFields.notes}
              value={formState.notes || ''}
              placeholder="Add a note"
              rows={3}
              onChange={handleChange}
            />
          </div>
        </>
      )}
    </Form>
  );
}
