import axios from 'axios';

import { PopulatedLocation, LocationModel } from '@api/models';

export class LocationsHttpService {
  static getLocations() {
    return axios.get<PopulatedLocation[]>(this.path('locations'));
  }

  static addLocation(address: string) {
    return axios.post<LocationModel>(this.path('locations'), { address });
  }

  static updateLocation(locationId: string, address: string) {
    return axios.put<LocationModel>(this.path(`locations/${locationId}`), {
      address
    });
  }

  static deleteLocation(locationId: string) {
    return axios.delete<any>(this.path(`locations/${locationId}`));
  }

  static path(path: string): string {
    return `/api/${path}`;
  }
}
